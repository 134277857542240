"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTitle = exports.StylePredictionsContainer = exports.StyleMainContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMainContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-grti8h-0"
})(["position:absolute;width:100%;bottom:50px;left:0;font-family:\"futura-pt\",sans-serif;display:grid;"]);
exports.StyleMainContainer = StyleMainContainer;

const StyleTitle = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleTitle",
  componentId: "sc-grti8h-1"
})(["line-height:40px;color:#FFFFFF;font-size:30px;font-weight:700;background:linear-gradient(to right,#F00020 0%,#540035 100%);padding:5px 50px;margin-bottom:10px;width:405px;"]);

exports.StyleTitle = StyleTitle;

const StylePredictionsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePredictionsContainer",
  componentId: "sc-grti8h-2"
})(["display:flex;flex-flow:nowrap;width:100%;min-height:170px;"]);

exports.StylePredictionsContainer = StylePredictionsContainer;