"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamLogoContainer = exports.StyleRank = exports.StyleDataContainer = exports.StyleChampionsCenterContainer = exports.StatsNameSideContainer = exports.StatsNameContainer = exports.StatsContainer = exports.Stats = exports.StatName = exports.Stat = exports.RightContainer = exports.PositionLogo = exports.PlayerName = exports.PlayerInfo = exports.Player = exports.LeftContainer = exports.Container = exports.ChampionsRightContainer = exports.ChampionsLeftContainer = exports.ChampionsContainer = exports.ChampionName = exports.ChampionImageContainer = exports.ChampionImage = exports.Champion = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const handleChampionsColumns = elements => {
  switch (true) {
    case elements === 1:
      return '656px';

    case elements === 2:
      return 'repeat(2, 314px)';

    case elements === 3:
      return 'repeat(3, 200px)';

    default:
      break;
  }
};

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-yxsujs-0"
})(["position:absolute;bottom:0;width:1920px;left:0;height:253px;font-family:inherit;color:white;font-weight:700;"]);

exports.Base = Base;

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-yxsujs-1"
})(["position:relative;width:100%;height:100%;overflow:hidden;"]);

exports.Container = Container;

const PlayerAnimation = _reactPose.default.div({
  hide: {
    x: _ref => {
      let {
        position
      } = _ref;
      return position === 'left' ? '-960px' : '960px';
    },
    height: 0,
    y: '-55px',
    transition: {
      duration: 400
    }
  },
  display: {
    x: '0px',
    height: '56px',
    y: '-55px',
    opacity: 0,
    transition: {
      duration: 400
    }
  },
  displayInfo: {
    height: '56px',
    opacity: 1,
    y: '-55px'
  }
});

const Player = (0, _styledComponents.default)(PlayerAnimation).withConfig({
  displayName: "styles__Player",
  componentId: "sc-yxsujs-2"
})(["position:absolute;transform:", ";left:", ";right:", ";font-family:futura-pt,sans-serif;height:56px;width:960px;overflow:hidden;"], props => props.position !== 'left' ? 'translate(960px, -150px)' : 'translate(800px, -150px)', props => props.position === 'left' ? 0 : 'unset', props => props.position === 'right' ? 0 : 'unset');
exports.Player = Player;

const PlayerInfoAnimation = _reactPose.default.div({
  hide: {
    y: '55px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '55px',
    transition: {
      duration: 400
    }
  },
  displayInfo: {
    y: '0px',
    transition: {
      duration: 400
    }
  }
});

const PlayerInfo = (0, _styledComponents.default)(PlayerInfoAnimation).withConfig({
  displayName: "styles__PlayerInfo",
  componentId: "sc-yxsujs-3"
})(["display:flex;align-items:center;height:100%;font-weight:700;justify-content:", ";padding-right:", ";padding-left:", ";background:", ";background-size:cover;transform:translateY(55px);"], props => props.position === 'right' ? 'flex-end' : 'flex-start', props => props.position === 'right' ? '54px' : 0, props => props.position === 'left' ? '54px' : 0, props => "url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/keyplayer-tile-".concat(props.position || 'left', ".png) no-repeat"));
exports.PlayerInfo = PlayerInfo;

const PositionLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__PositionLogo",
  componentId: "sc-yxsujs-4"
})(["background-image:", ";background-repeat:no-repeat;background-size:60px;height:60px;width:60px;"], props => "url(".concat(props.logo, ")"));

exports.PositionLogo = PositionLogo;

const PlayerName = _styledComponents.default.span.withConfig({
  displayName: "styles__PlayerName",
  componentId: "sc-yxsujs-5"
})(["text-transform:uppercase;font-size:40px;letter-spacing:2px;margin-left:20px;margin-right:20px;color:#141720;line-height:40px;"]);

exports.PlayerName = PlayerName;

const StatsContainerAnimation = _reactPose.default.div({
  hide: {
    y: '253px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '0px',
    transition: {
      duration: 400
    }
  },
  push: {
    y: '-253px',
    transition: {
      duration: 400
    }
  }
});

const StatsContainer = (0, _styledComponents.default)(StatsContainerAnimation).withConfig({
  displayName: "styles__StatsContainer",
  componentId: "sc-yxsujs-6"
})(["width:100%;height:100%;display:grid;grid-template-columns:750px 420px 750px;overflow:hidden;transform:translateY(253px);background:#030d23;"]);
exports.StatsContainer = StatsContainer;

const LeftContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__LeftContainer",
  componentId: "sc-yxsujs-7"
})(["position:relative;display:flex;flex:4;height:100%;"]);

exports.LeftContainer = LeftContainer;

const StatsNameContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StatsNameContainer",
  componentId: "sc-yxsujs-8"
})(["display:grid;grid-template-columns:repeat(2,1fr);padding:26px 0;background-color:#141720;div:first-of-type{border-right:1px #ffffff solid;}"]);

exports.StatsNameContainer = StatsNameContainer;

const StatsNameSideContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StatsNameSideContainer",
  componentId: "sc-yxsujs-9"
})(["display:flex;flex:1;background-color:#141720;color:white;display:flex;flex-direction:column;justify-content:center;font-size:28px;height:100%;text-align:center;font-family:futura-pt,sans-serif;font-weight:400;div:first-of-type{border-right:unset;}"]);

exports.StatsNameSideContainer = StatsNameSideContainer;

const RightContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__RightContainer",
  componentId: "sc-yxsujs-10"
})(["position:relative;display:flex;flex:4;height:100%;"]);

exports.RightContainer = RightContainer;

const TeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamLogoContainer",
  componentId: "sc-yxsujs-11"
})(["position:absolute;top:0;bottom:0;left:0;right:0;overflow:hidden;width:100%;"]);

exports.TeamLogoContainer = TeamLogoContainer;

const StyleDataContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDataContainer",
  componentId: "sc-yxsujs-12"
})(["position:absolute;display:grid;height:100%;right:", ";left:", ";grid-template-columns:", ";padding-right:", ";padding-left:", ";"], props => props.position === 'right' ? 'unset' : 0, props => props.position === 'left' ? 'unset' : 0, props => props.position === 'left' ? 'auto 70px ' : '70px auto', props => props.position === 'left' ? '10px' : 0, props => props.position === 'right' ? '10px' : 0);

exports.StyleDataContainer = StyleDataContainer;

const Stats = _styledComponents.default.div.withConfig({
  displayName: "styles__Stats",
  componentId: "sc-yxsujs-13"
})(["z-index:1;font-family:futura-pt,sans-serif;font-weight:700;right:", ";left:", ";display:flex;justify-content:center;font-size:55px;color:#141720;flex-direction:column;height:100%;padding-right:", ";padding-left:", ";letter-spacing:3px;"], props => props.position === 'right' ? 'unset' : 0, props => props.position === 'left' ? 'unset' : 0, props => props.position === 'left' ? '5px' : 0, props => props.position === 'right' ? '5px' : 0);

exports.Stats = Stats;

const Stat = _styledComponents.default.div.withConfig({
  displayName: "styles__Stat",
  componentId: "sc-yxsujs-14"
})(["font-size:50px;line-height:50px;height:65px;display:flex;align-items:center;justify-content:", ";"], props => props.position === 'left' ? 'flex-end' : 'flex-start');

exports.Stat = Stat;

const StatName = _styledComponents.default.div.withConfig({
  displayName: "styles__StatName",
  componentId: "sc-yxsujs-15"
})(["display:flex;justify-content:center;align-items:center;font-size:28px;text-align:center;line-height:28px;overflow:hidden;height:65px;"]);

exports.StatName = StatName;

const StyleRank = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRank",
  componentId: "sc-yxsujs-16"
})(["display:flex;align-items:flex-end;justify-content:center;line-height:50px;font-size:20px;line-height:20px;font-weight:400;height:65px;color:#141720;padding:13px 0;"]);

exports.StyleRank = StyleRank;

const ChampionsContainerAnimation = _reactPose.default.div({
  hide: {
    y: '0px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '-253px',
    transition: {
      duration: 400
    }
  }
});

const ChampionsContainer = (0, _styledComponents.default)(ChampionsContainerAnimation).withConfig({
  displayName: "styles__ChampionsContainer",
  componentId: "sc-yxsujs-17"
})(["width:100%;height:100%;overflow:hidden;padding:0 54px;background-color:#141720;display:grid;grid-template-columns:656px 500px 656px;overflow:hidden;"]);
exports.ChampionsContainer = ChampionsContainer;

const StyleChampionsCenterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionsCenterContainer",
  componentId: "sc-yxsujs-18"
})(["display:flex;flex:1;background-color:#141720;color:white;display:flex;flex-direction:column;justify-content:center;font-size:55px;height:100%;text-align:center;font-family:futura-pt,sans-serif;font-weight:700;text-transform:uppercase;"]);

exports.StyleChampionsCenterContainer = StyleChampionsCenterContainer;

const ChampionsLeftContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionsLeftContainer",
  componentId: "sc-yxsujs-19"
})(["position:relative;display:grid;grid-template-columns:", ";grid-column-gap:28px;flex-direction:row;flex:1;height:100%;"], _ref2 => {
  let {
    championsLength
  } = _ref2;
  return handleChampionsColumns(championsLength);
});

exports.ChampionsLeftContainer = ChampionsLeftContainer;

const ChampionsRightContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionsRightContainer",
  componentId: "sc-yxsujs-20"
})(["position:relative;display:grid;grid-column-gap:28px;grid-template-columns:", ";flex-direction:row;flex:1;height:100%;"], _ref3 => {
  let {
    championsLength
  } = _ref3;
  return handleChampionsColumns(championsLength);
});

exports.ChampionsRightContainer = ChampionsRightContainer;

const Champion = _styledComponents.default.div.withConfig({
  displayName: "styles__Champion",
  componentId: "sc-yxsujs-21"
})(["display:flex;flex-direction:column;width:100%;height:253px;"]);

exports.Champion = Champion;

const ChampionName = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionName",
  componentId: "sc-yxsujs-22"
})(["flex:2;font-size:30px;text-align:center;letter-spacing:1px;line-height:1.7;"]);

exports.ChampionName = ChampionName;

const ChampionImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionImageContainer",
  componentId: "sc-yxsujs-23"
})(["flex:8;height:100%;overflow:hidden;"]);

exports.ChampionImageContainer = ChampionImageContainer;

const ChampionImage = _styledComponents.default.img.withConfig({
  displayName: "styles__ChampionImage",
  componentId: "sc-yxsujs-24"
})(["width:", ";height:100%;object-fit:cover;max-width:100%;object-position:", ";"], props => props.championsLength === 1 ? '100%' : props.championsLength === 2 ? '100%' : 'auto', props => props.championsLength === 1 ? 'center -15px' : 'center');

exports.ChampionImage = ChampionImage;