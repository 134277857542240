"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sideColor = void 0;
const sideColor = {
  team100: "#0078ff",
  team200: "#dd3217"
};
exports.sideColor = sideColor;