"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyleTeamReccordHeader = exports.StyleTeamReccord = exports.StyleTeamPosHeader = exports.StyleTeamPos = exports.StyleTeamNameHeader = exports.StyleTeamName = exports.StyleTeamLogoHeader = exports.StyleTeamLogo = exports.StyleStandingsContainer = exports.StyleStandingTitle = exports.StyleStandingSingle = exports.StyleStandingRowHeader = exports.StyleStandingRow = exports.StyleStandingHeaderItem = exports.StyleStanding = exports.StyleMain = exports.Spacers = exports.FAKEStyleStandingRow = exports.BorderLine = void 0;

var _reactSpring = require("react-spring");

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-1lxwvrh-0"
})(["width:1920px;height:1080px;position:absolute;top:0;"]);

exports.StyleMain = StyleMain;

const StyleStandingsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStandingsContainer",
  componentId: "sc-1lxwvrh-1"
})(["grid-gap:", "%;position:absolute;margin:auto;width:", ";height:620px;top:", ";left:231px;padding:", ";grid-template-columns:", ";background-repeat:no-repeat;background-size:100% 100%;transition:transform 500ms ease-in-out,top 1s ease-in-out,width 500ms ease-in-out,padding 500ms ease-in-out;transform:", ";display:", ";flex-wrap:wrap;justify-content:space-evenly;align-items:stretch;z-index:100;flex-direction:", ";background-position:center;background-repeat:no-repeat;background-size:cover;", ";", ";", ";", ";", ";", ";", ";", ";", ";"], props => props.isQuadruple ? '0' : '15', props => props.isresized ? '1180px' : '1460px', props => props.show ? '299px' : '1393px', props => props.isresized ? '60px 110px 60px 110px;' : '60px 160px 60px 160px', props => "repeat(".concat(props.standingsCount || 1, ", 1fr)"), props => "translateX(".concat(props.isresized ? '419px' : '0', ")"), props => props.isQuadruple ? 'flex' : 'grid', props => props.isQuadruple ? "column" : "row", props => props.isQuadruple ? "gap: 50px" : '', props => !props.isresized && props.isDouble ? "gap: 25%" : "", props => props.isresized && props.isDouble ? "gap: 18%" : "", props => props.isTriple ? "gap: 5%" : "", props => props.isQuadruple ? 'padding: 50px 6%;' : '', props => props.css || '', props => props.isSingle ? props.StyleStandingsContainerSingle : '', props => props.isresized ? props.standingsResizeOverWrite || '' : '', props => props.isQuadruple && props.isresized && props.StyleStandingsContainerQuadrupleResizedOverWrite ? props.StyleStandingsContainerQuadrupleResizedOverWrite : '');

exports.StyleStandingsContainer = StyleStandingsContainer;

const StyleStanding = _styledComponents.default.li.withConfig({
  displayName: "styles__StyleStanding",
  componentId: "sc-1lxwvrh-2"
})(["display:grid;font-size:28px;overflow:hidden;flex:1;order:1;width:100%;gap:2%;", ";", ";", ";", ";", ";", ";"], props => props.isquadruple ? "gap: 0%;" : '', props => props.isquadruple && props.isresized ? "grid-template-columns: 1.5fr 2fr;" : '', props => props.isquadruple && !props.isresized ? "grid-template-columns: 1.6fr 3fr;" : '', props => props.StyleStanding ? props.StyleStanding : '', props => props.isSingle && props.StyleStandingSingle ? props.StyleStandingSingle : '', props => props.StyleStandingOverride || '');

exports.StyleStanding = StyleStanding;

const StyleStandingSingle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStandingSingle",
  componentId: "sc-1lxwvrh-3"
})(["display:grid;font-size:28px;overflow:hidden;flex:1;order:1;width:100%;gap:2%;", ";", ";", ";", ";"], props => props.isquadruple ? "gap: 0%;" : '', props => props.isquadruple && props.isresized ? "grid-template-columns: 1.5fr 2fr;" : '', props => props.isquadruple && !props.isresized ? "grid-template-columns: 1.6fr 3fr;" : '', props => props.StyleStandingSingle ? props.StyleStandingSingle : '');

exports.StyleStandingSingle = StyleStandingSingle;

const Spacers = _styledComponents.default.div.withConfig({
  displayName: "styles__Spacers",
  componentId: "sc-1lxwvrh-4"
})(["flex:1;width:100%;height:50%;display:flex;gap:6%;", ";", ";", ";"], props => props.isquadruple ? "gap: 10%;" : '', props => props.Spacers ? props.Spacers : '', props => props.isresized && props.SpacersResized ? props.SpacersResized : '');

exports.Spacers = Spacers;

const StyleStandingTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStandingTitle",
  componentId: "sc-1lxwvrh-5"
})(["", ";text-transform:uppercase;grid-column:1 / -1;align-items:flex-end;color:", ";", ";"], props => props.isquadruple ? "" : 'height: 50px;', props => props.mainColor || '#54cc4f', props => props.groupTitle || '');

exports.StyleStandingTitle = StyleStandingTitle;
const StyleStandingRow = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleStandingRow",
  componentId: "sc-1lxwvrh-6"
})(["display:flex;height:56px;", ";", ";", ";", ";", ";", " "], props => props.isquadruple ? "align-items: center;" : '', props => props.$StyleStandingRow ? props.$StyleStandingRow : '', props => props.issingle && props.$StyleStandingRowSingle ? props.$StyleStandingRowSingle : '', props => !props.isresized && props.issingle && props.$StyleStandingRowSingle ? props.$StyleStandingRowSingle : '', props => props.isresized && props.issingle && props.$StyleStandingRowSingleResize ? props.$StyleStandingRowSingleResize : '', props => props.finalrow && props.issingle && props.$StyleStandingRowSingle ? 'border-bottom: none; ' : '');
exports.StyleStandingRow = StyleStandingRow;
const StyleStandingRowHeader = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleStandingRowHeader",
  componentId: "sc-1lxwvrh-7"
})(["display:flex;height:56px;", ";", ";", ";", ";", ";", ";"], props => props.isquadruple ? "align-items: center;" : '', props => !props.isresized && props.$StyleStandingRowHeader ? props.$StyleStandingRowHeader : '', props => props.isresized && props.$StyleStandingRowHeaderResize ? props.$StyleStandingRowHeaderResize : '', props => !props.isresized && props.issingle && props.$StyleStandingRowHeaderSingle ? props.$StyleStandingRowHeaderSingle : '', props => props.isresized && props.issingle && props.$StyleStandingRowHeaderSingleResize ? props.$StyleStandingRowHeaderSingleResize : '', props => props.issingle && props.$StyleStandingRowHeaderSingle4RowOverride ? props.$StyleStandingRowHeaderSingle4RowOverride : '');
exports.StyleStandingRowHeader = StyleStandingRowHeader;
const FAKEStyleStandingRow = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__FAKEStyleStandingRow",
  componentId: "sc-1lxwvrh-8"
})(["display:grid;grid-template-columns:2fr 1.4fr 10fr 1.5fr;", " height:56px;opacity:0;", ";"], props => !props.issingle && "grid-template-columns: 1fr 1fr 1.2fr;" || '', props => props.FAKEStyleStandingRow || '');
exports.FAKEStyleStandingRow = FAKEStyleStandingRow;

const StyleStandingHeaderItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStandingHeaderItem",
  componentId: "sc-1lxwvrh-9"
})(["font-size:21px;line-height:21px;display:flex;justify-content:left;align-items:flex-end;margin-bottom:14px;", ";"], props => props.StyleStandingHeaderItem || '');

exports.StyleStandingHeaderItem = StyleStandingHeaderItem;

const StyleTeamPosHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamPosHeader",
  componentId: "sc-1lxwvrh-10"
})(["display:flex;align-items:center;width:100px;", ";"], props => props.StyleTeamPosHeader || '');

exports.StyleTeamPosHeader = StyleTeamPosHeader;

const StyleTeamLogoHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoHeader",
  componentId: "sc-1lxwvrh-11"
})(["display:flex;img{height:50px;width:50px;object-fit:contain;}width:80px;", ";width:", ";"], props => props.StyleTeamLogoHeader ? props.StyleTeamLogoHeader : '', props => !props.issingle && props.isresized ? '0px' : '');

exports.StyleTeamLogoHeader = StyleTeamLogoHeader;

const StyleTeamNameHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamNameHeader",
  componentId: "sc-1lxwvrh-12"
})(["display:flex;align-items:center;text-transform:uppercase;", ";", ";", ";", ";"], props => !props.issingle && props.StyleTeamNameHeader ? props.StyleTeamNameHeader : '', props => !props.issingle && props.isresized && props.StyleTeamNameHeaderResize ? props.StyleTeamNameHeaderResize : '', props => props.issingle && props.StyleTeamNameHeaderSingle ? props.StyleTeamNameHeaderSingle : '', props => props.issingle && props.isresized && props.StyleTeamNameHeaderSingleResize ? props.StyleTeamNameHeaderSingleResize : '');

exports.StyleTeamNameHeader = StyleTeamNameHeader;

const StyleTeamReccordHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamReccordHeader",
  componentId: "sc-1lxwvrh-13"
})(["display:flex;align-items:center;margin-left:auto;", ";", ";", ";"], props => !props.issingle && props.StyleTeamReccordHeader ? props.StyleTeamReccordHeader : '', props => props.issingle && props.StyleTeamReccordHeaderSingle ? props.StyleTeamReccordHeaderSingle : '', props => props.issingle && props.isresized && props.StyleTeamReccordHeaderSingleResized ? props.StyleTeamReccordHeaderSingleResized : '');

exports.StyleTeamReccordHeader = StyleTeamReccordHeader;

const StyleTeamPos = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamPos",
  componentId: "sc-1lxwvrh-14"
})(["display:flex;align-items:center;width:100px;", ";"], props => props.StyleTeamPos ? props.StyleTeamPos : '');

exports.StyleTeamPos = StyleTeamPos;

const StyleTeamLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1lxwvrh-15"
})(["display:flex;img{height:50px;width:50px;object-fit:contain;}width:80px;", " ", ""], props => props.StyleTeamLogo ? props.StyleTeamLogo : '', props => props.issingle && props.StyleTeamLogoSingle ? props.StyleTeamLogoSingle : '');

exports.StyleTeamLogo = StyleTeamLogo;

const StyleTeamName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamName",
  componentId: "sc-1lxwvrh-16"
})(["display:flex;align-items:center;text-transform:uppercase;", ";", ";"], props => !props.issingle && props.StyleTeamName ? props.StyleTeamName : '', props => props.issingle && props.StyleTeamNameSingle ? props.StyleTeamNameSingle : '');

exports.StyleTeamName = StyleTeamName;

const StyleTeamReccord = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamReccord",
  componentId: "sc-1lxwvrh-17"
})(["display:flex;align-items:center;margin-left:auto;", ";", ";", ";"], props => !props.issingle && props.StyleTeamReccord ? props.StyleTeamReccord : '', props => props.issingle && props.StyleTeamReccordSingle ? props.StyleTeamReccordSingle : '', props => props.issingle && props.isresized && props.StyleTeamReccordSingleResized ? props.StyleTeamReccordSingleResized : '');

exports.StyleTeamReccord = StyleTeamReccord;

const BorderLine = _styledComponents.default.div.withConfig({
  displayName: "styles__BorderLine",
  componentId: "sc-1lxwvrh-18"
})([""]);

exports.BorderLine = BorderLine;

const Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1lxwvrh-19"
})([""]);

exports.Wrapper = Wrapper;