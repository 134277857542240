"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StylePositionImgContainer = exports.StylePositionBox = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StylePositionBox = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePositionBox",
  componentId: "sc-6fkad7-0"
})(["display:flex;justify-content:center;align-items:center;height:80px;width:287px;background-color:#141720;margin-left:10px;", ""], props => props.cssPosition);

exports.StylePositionBox = StylePositionBox;

const StylePositionImgContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePositionImgContainer",
  componentId: "sc-6fkad7-1"
})([""]);

exports.StylePositionImgContainer = StylePositionImgContainer;