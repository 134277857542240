"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamRowsContainer = exports.TeamRowCell = exports.TeamRow = exports.TeamName = exports.TableHeader = exports.Subtext = exports.StyleHyphen = exports.Score = exports.Rank = exports.Logo = exports.HeaderSubtext = exports.HeaderScore = exports.HeaderRank = exports.HeaderName = exports.HeaderLogo = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const TeamRowsContainerAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  hidden: {
    transition: {
      duration: 200
    },
    staggerChildren: 200,
    staggerDirection: -1,
    afterChildren: true
  },
  normal: {
    transition: {
      duration: 200
    },
    staggerChildren: 200,
    staggerDirection: 1,
    delay: 1000
  }
});

const TeamRowsContainer = (0, _styledComponents.default)(TeamRowsContainerAnimation).withConfig({
  displayName: "styles__TeamRowsContainer",
  componentId: "sc-7ztkm8-0"
})(["display:flex;flex-direction:column;justify-content:center;height:100%;width:100%;", " ", ""], props => props.background && "background: ".concat(props.background, ";"), props => props.cssTeamRowsContainer || '');
exports.TeamRowsContainer = TeamRowsContainer;

const TeamRowAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  hidden: {
    opacity: 0
  },
  normal: {
    opacity: 1
  }
});

const TableHeader = (0, _styledComponents.default)(TeamRowAnimation).withConfig({
  displayName: "styles__TableHeader",
  componentId: "sc-7ztkm8-1"
})(["display:grid;grid-template-columns:1fr 3fr 19fr 3fr 3fr;align-items:center;font-size:30px;line-height:1em;width:100%;margin-bottom:10px;", ""], props => props.cssHeaderCell);
exports.TableHeader = TableHeader;

const HeaderRank = _styledComponents.default.div.withConfig({
  displayName: "styles__HeaderRank",
  componentId: "sc-7ztkm8-2"
})(["text-align:center;flex-basis:12%;", ""], props => props.cssHeaderRank);

exports.HeaderRank = HeaderRank;

const HeaderSubtext = _styledComponents.default.div.withConfig({
  displayName: "styles__HeaderSubtext",
  componentId: "sc-7ztkm8-3"
})(["text-align:center;flex-basis:12%;"]);

exports.HeaderSubtext = HeaderSubtext;

const HeaderLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__HeaderLogo",
  componentId: "sc-7ztkm8-4"
})(["flex-basis:12%;text-align:center;"]);

exports.HeaderLogo = HeaderLogo;

const HeaderName = _styledComponents.default.div.withConfig({
  displayName: "styles__HeaderName",
  componentId: "sc-7ztkm8-5"
})(["padding-left:32px;", ""], props => props.cssHeaderName ? props.cssHeaderName : '');

exports.HeaderName = HeaderName;

const HeaderScore = _styledComponents.default.div.withConfig({
  displayName: "styles__HeaderScore",
  componentId: "sc-7ztkm8-6"
})(["text-align:center;", ""], props => props.cssHeaderScore ? props.cssHeaderScore : '');

exports.HeaderScore = HeaderScore;
const TeamRow = (0, _styledComponents.default)(TeamRowAnimation).withConfig({
  displayName: "styles__TeamRow",
  componentId: "sc-7ztkm8-7"
})(["display:grid;grid-template-columns:1fr 3fr 19fr 3fr 3fr;margin:", ";width:100%;border:transparent 2px;z-index:2;flex:1 1 60px;max-height:130px;overflow:hidden;align-items:stretch;", ""], props => props.last ? '0px' : '0 0 10px 0', props => props.csscell);
exports.TeamRow = TeamRow;

const TeamRowCell = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamRowCell",
  componentId: "sc-7ztkm8-8"
})(["align-items:center;display:flex;flex-grow:1;justify-content:center;position:relative;"]);

exports.TeamRowCell = TeamRowCell;
const Rank = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Rank",
  componentId: "sc-7ztkm8-9"
})(["font-weight:800;flex-basis:12%;background:", ";", ""], props => "linear-gradient(0deg, ".concat(props.backgroundColor && props.backgroundColor[0], " 0%, ").concat(props.backgroundColor && props.backgroundColor[1], " 100%)"), props => props.cssRankCell);
exports.Rank = Rank;
const Subtext = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Subtext",
  componentId: "sc-7ztkm8-10"
})(["font-weight:800;flex-basis:12%;", ""], props => props.cssSubtextCell);
exports.Subtext = Subtext;
const Logo = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Logo",
  componentId: "sc-7ztkm8-11"
})(["overflow:hidden;background-image:url(", ");background-repeat:no-repeat;background-size:auto 50%;background-position:center;:before{background-color:", ";}", ""], props => props.logoImage, props => props.teamMainColor, props => props.cssLogoCell);
exports.Logo = Logo;
const TeamName = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-7ztkm8-12"
})(["letter-spacing:2px;padding-left:40px;justify-content:left;", ""], props => props.cssNameCell);
exports.TeamName = TeamName;
const Score = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Score",
  componentId: "sc-7ztkm8-13"
})(["margin-left:10px;flex:2;", ""], props => props.cssScoreCell);
exports.Score = Score;

const StyleHyphen = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleHyphen",
  componentId: "sc-7ztkm8-14"
})(["position:relative;top:-3px;right:-1px;"]);

exports.StyleHyphen = StyleHyphen;