"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTileTopRowContainer = exports.StyleTile = exports.StyleRowContainer = exports.StyleHeader = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const animation = _reactPose.default.div({
  hide: {
    delay: 2300,
    clipPath: "inset(0 100% 0 0)",
    transition: {
      duration: 400
    },
    staggerChildren: 400,
    delayChildren: 400,
    staggerDirection: -1
  },
  show: {
    clipPath: "inset(0 0% 0 0)",
    transition: {
      duration: 400
    },
    staggerChildren: 400,
    delayChildren: 400,
    staggerDirection: 1
  }
});

const StyleTile = (0, _styledComponents.default)(animation).withConfig({
  displayName: "style__StyleTile",
  componentId: "sc-3mr0ju-0"
})(["height:100%;margin-right:25px;width:590px;line-height:1;"]);
exports.StyleTile = StyleTile;
const StyleHeader = (0, _styledComponents.default)(animation).withConfig({
  displayName: "style__StyleHeader",
  componentId: "sc-3mr0ju-1"
})(["background-color:#7d5a0a;align-items:center;color:white;display:flex;font-size:60px;font-weight:300;height:110px;justify-content:center;margin-bottom:7px;position:relative;width:100%;", ""], props => props.cssHeader);
exports.StyleHeader = StyleHeader;

const rowAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    delayChildren: 600,
    staggerChildren: 400,
    staggerDirection: -1
  },
  show: {
    delayChildren: 600,
    staggerChildren: 400,
    staggerDirection: 1
  }
});

const StyleTileTopRowContainer = (0, _styledComponents.default)(animation).withConfig({
  displayName: "style__StyleTileTopRowContainer",
  componentId: "sc-3mr0ju-2"
})([""]);
exports.StyleTileTopRowContainer = StyleTileTopRowContainer;

const StyleRowContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowContainer",
  componentId: "sc-3mr0ju-3"
})([""]);

exports.StyleRowContainer = StyleRowContainer;