"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tag = exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTools = require("styled-tools");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-tx3b7t-0"
})(["position:relative;flex:1;height:100%;align-items:center;justify-content:center;display:flex;z-index:1;font-size:70px;letter-spacing:5px;justify-content:space-around;", " ", ""], props => props.separatorColor && "border-right: 7px solid ".concat(props.separatorColor, ";"), props => props.separatorColor && "border-left: 7px solid ".concat(props.separatorColor, ";"));

exports.Container = Container;

const Tag = _styledComponents.default.div.withConfig({
  displayName: "styles__Tag",
  componentId: "sc-tx3b7t-1"
})(["position:relative;text-align:center;width:", ";height:100%;display:flex;justify-content:center;align-items:center;margin-right:", ";margin-left:", ";", " ", " ", ";"], props => props.single ? '140px' : '70px', props => (props.firstTag || props.single) && !props.nextMatch ? '8px' : '0', props => props.single && !props.nextMatch ? '8px' : '0', props => props.cssScore, props => props.cssAdjust, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;"])));

exports.Tag = Tag;