"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTopImage = exports.StylePicksRow = exports.StyleMain = exports.StyleContent = exports.StyleChampionsStatsContainer = exports.StyleBlackBg = exports.StyleBansRow = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleChampionsStatsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionsStatsContainer",
  componentId: "sc-ky5z37-0"
})(["width:100%;height:100%;position:absolute;top:0;left:0;*{z-index:10;};"]);

exports.StyleChampionsStatsContainer = StyleChampionsStatsContainer;

const StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-ky5z37-1"
})(["*{}width:1920px;height:1080px;"]);

exports.StyleMain = StyleMain;
const StyleTopImage = (0, _styledComponents.default)(_reactSpring.animated.img).withConfig({
  displayName: "styles__StyleTopImage",
  componentId: "sc-ky5z37-2"
})(["width:486px;object-fit:contain;position:absolute;left:50%;transform:translate(-50%,0);"]);
exports.StyleTopImage = StyleTopImage;

const StyleContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContent",
  componentId: "sc-ky5z37-3"
})(["position:absolute;left:0;right:0;bottom:0;height:430px;display:grid;grid-template-rows:200px 230px;"]);

exports.StyleContent = StyleContent;
const StyleBlackBg = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleBlackBg",
  componentId: "sc-ky5z37-4"
})(["position:absolute;height:230px;width:1920px;bottom:0;background-color:#141720;"]);
exports.StyleBlackBg = StyleBlackBg;

const StyleBansRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBansRow",
  componentId: "sc-ky5z37-5"
})(["display:grid;grid-template-columns:395px repeat(3,85px) 35px repeat(2,85px) 210px repeat(2,85px) 35px repeat(3,85px) 395px;overflow:hidden;*{z-index:2;};"]);

exports.StyleBansRow = StyleBansRow;

const StylePicksRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePicksRow",
  componentId: "sc-ky5z37-6"
})(["display:grid;grid-template-columns:repeat(5,171px) 210px repeat(5,171px);*{z-index:2;};"]);

exports.StylePicksRow = StylePicksRow;