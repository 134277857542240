"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideo = exports.StyleTitle = exports.StyleTime = exports.StyleScheduleContainer = exports.StyleRowContainer = exports.StyleMatch = exports.StyleLogoContainer = exports.StyleGame = exports.StyleDay = exports.StyleDate = exports.StyleBase = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const newPoseAnimation = _reactPose.default.div({
  initialPose: 'hide',
  show: {
    opacity: 1,
    transition: {
      duration: 600
    }
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 600
    },
    delay: 1200
  }
});

const StyleBase = (0, _styledComponents.default)(newPoseAnimation).withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-1ga07ru-0"
})(["position:relative;color:white;font-weight:100;display:flex;flex-direction:row;justify-content:center;align-items:center;font-family:inherit;font-size:40px;letter-spacing:2px;overflow:hidden;width:1920px;height:1080px;background-color:gray;background:url(", ");background-size:100% 100%;"], props => props.baseBG);
exports.StyleBase = StyleBase;

const StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-1ga07ru-1"
})(["height:100%;width:100%;background-color:transparent;position:absolute;z-index:0;"]);

exports.StyleVideo = StyleVideo;

const ContainerAnimation = _reactPose.default.div({
  show: {
    y: _ref => {
      let {
        resize
      } = _ref;
      return resize ? -75 : 0;
    },
    scale: _ref2 => {
      let {
        resize
      } = _ref2;
      return resize ? 0.8 : 1;
    },
    transition: _ref3 => {
      let {
        speedPanel
      } = _ref3;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: _ref4 => {
      let {
        speedPanel
      } = _ref4;
      return speedPanel;
    },
    staggerDirection: 1
  },
  hide: {
    y: _ref5 => {
      let {
        resize
      } = _ref5;
      return resize ? -75 : 0;
    },
    scale: _ref6 => {
      let {
        resize
      } = _ref6;
      return resize ? 0.8 : 1;
    },
    transition: _ref7 => {
      let {
        speedPanel
      } = _ref7;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: _ref8 => {
      let {
        speedPanel
      } = _ref8;
      return speedPanel;
    },
    staggerDirection: -1
  }
});

const StyleLogoContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleLogoContainer",
  componentId: "sc-1ga07ru-2"
})(["position:absolute;left:0%;display:flex;flex-direction:column;align-items:center;justify-content:center;width:45%;height:100%;background:url(", ");background-size:100% 100%;img{width:650px;height:auto;}", ""], props => props.scheduleBG, props => props.css);
exports.StyleLogoContainer = StyleLogoContainer;
const StyleScheduleContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleScheduleContainer",
  componentId: "sc-1ga07ru-3"
})(["position:absolute;left:45%;display:flex;flex-direction:column;align-items:center;justify-content:center;width:50%;font-weight:700;height:100%;background:url(", ");background-size:100% 100%;", ""], props => props.scheduleBG, props => props.css);
exports.StyleScheduleContainer = StyleScheduleContainer;

const RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    y: -200,
    z: 1,
    scale: 1,
    opacity: 0,
    transition: {
      duration: 400
    },
    applyAtStart: {
      scale: 1
    }
  },
  show: {
    y: 0,
    z: 1,
    scale: _ref9 => {
      let {
        nextMatch
      } = _ref9;
      return nextMatch ? 1.03 : 1;
    },
    opacity: 1,
    transition: {
      duration: 400
    },
    delay: 100,
    delayChildren: 400
  }
});

const StyleTitle = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleTitle",
  componentId: "sc-1ga07ru-4"
})(["position:relative;top:2%;align-items:center;margin-bottom:50px;justify-content:center;width:90%;font-weight:700;font-size:80px;font-family:AgencyFB;font-weight:800;padding:20px;text-align:center;color:#000000;background:url(", ");background-size:100% 100%;", ""], props => props.headerBGsrc, props => props.css);
exports.StyleTitle = StyleTitle;
const StyleRowContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowContainer",
  componentId: "sc-1ga07ru-5"
})(["position:relative;display:flex;flex-direction:row;justify-content:center;align-items:flex-start;margin-bottom:5px;padding-left:", ";padding-right:", ";z-index:", ";width:90%;flex:1 1 110px;max-height:120px;height:", ";", " color:", ";text-align:center;text-transform:uppercase;font-size:65px;font-family:AgencyFB;font-weight:800;overflow:hidden;", " ", ""], props => props.nextMatch ? '20px' : '0px', props => props.nextMatch ? '20px' : '0px', props => props.nextMatch ? 30 : 2, props => props.nextMatch ? '105%' : '100%', props => props.nextMatch ? 'background-color: #f2a900;' : '', props => props.nextMatch ? '#942717' : props.isPassed ? '#333333' : '#f2a900', props => props.css, props => props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed);
exports.StyleRowContainer = StyleRowContainer;

const StyleDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDate",
  componentId: "sc-1ga07ru-6"
})(["position:relative;flex:1;align-items:center;justify-content:center;display:flex;flex-direction:column;height:100%;min-width:20%;text-transform:uppercase;line-height:40px;font-size:40px;z-index:4;color:", ";background-color:", ";", " ", ""], props => props.nextMatch ? '##942717' : props.isPassed ? '#333333' : '#f2a900', props => props.nextMatch ? '#f2a900' : '#000000', props => props.css, props => props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed);

exports.StyleDate = StyleDate;

const TeamAnimation = _reactPose.default.div({
  initialPose: 'show',
  show: {
    transition: {
      duration: 100
    }
  },
  showNext: {
    transition: {
      duration: 400
    }
  }
});

const StyleMatch = (0, _styledComponents.default)(TeamAnimation).withConfig({
  displayName: "styles__StyleMatch",
  componentId: "sc-1ga07ru-7"
})(["flex:4;z-index:4;position:relative;display:flex;justify-content:center;align-items:center;height:100%;width:40%;margin-right:20px;background-color:", ";border-right:", ";", " ", ""], props => props.nextMatch ? 'transparent' : '#000000', props => props.nextMatch ? '0px' : '10px solid transparent', props => props.css, props => props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed);
exports.StyleMatch = StyleMatch;
const StyleGame = (0, _styledComponents.default)(TeamAnimation).withConfig({
  displayName: "styles__StyleGame",
  componentId: "sc-1ga07ru-8"
})(["flex:4;z-index:4;position:relative;display:flex;justify-content:center;align-items:center;height:100%;border-right:", ";background-color:", ";", " ", ""], props => props.nextMatch ? '0px' : '10px solid transparent', props => props.nextMatch ? 'transparent' : '#000000', props => props.css, props => props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed);
exports.StyleGame = StyleGame;

const StyleDay = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleDay",
  componentId: "sc-1ga07ru-9"
})(["z-index:4;"]);

exports.StyleDay = StyleDay;

const StyleTime = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTime",
  componentId: "sc-1ga07ru-10"
})(["z-index:4;"]);

exports.StyleTime = StyleTime;