"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imageError = imageError;
exports.preloadImages = exports.preloadImage = exports.normalizeName = exports.loadImageUrlTeamTag = exports.loadImageUrlTeamLogo = exports.loadImageUrlTeamColorLogo = exports.loadImageUrlPosition = exports.loadImageUrlPlayerVoting = exports.loadImageUrlPlayerStarters = exports.loadImageUrlPlayerResultsGlow = exports.loadImageUrlPlayerResults = exports.loadImageUrlPlayerMatchup = exports.loadImageUrlChampSquare = exports.loadImageUrlChampCentered = exports.loadImageUrl = void 0;

// pass in the path to the desired file beneath the images folder
// eg. for accessing the file at "/src/images/results-logos/7h-results-logo.png"
// ______ pass in loadImageUrl('/results-logos/7h-results-logo.png')
const loadImageUrl = path => path || null;

exports.loadImageUrl = loadImageUrl;

const normalizeName = name => {
  return name ? name.replace(' ', '').toLowerCase() : null;
}; // specific asset requests


exports.normalizeName = normalizeName;

const loadImageUrlChampSquare = champ => {
  const url = "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/".concat(champ, ".png");
  return loadImageUrl(url);
};

exports.loadImageUrlChampSquare = loadImageUrlChampSquare;

const loadImageUrlPosition = position => {
  const url = "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/ljlwhite/".concat(position, ".svg");
  return loadImageUrl(url);
};

exports.loadImageUrlPosition = loadImageUrlPosition;

const loadImageUrlChampCentered = champ => {
  const url = "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/".concat(normalizeName(champ), ".jpg");
  return loadImageUrl(url);
};

exports.loadImageUrlChampCentered = loadImageUrlChampCentered;

const getPlayerImageUrl = (player, teamData, imageType) => {
  const roster = teamData.roster;
  const lowercaseRoster = {};

  if (roster) {
    Object.keys(roster).forEach(key => {
      lowercaseRoster[key.toLowerCase()] = roster[key];
    });
    const p = lowercaseRoster[player.toLowerCase()];
    if (!p) return;
    return p.images && p.images[imageType];
  }

  return '';
};

const getTeamImageUrl = (teamData, imageType) => {
  return teamData.images && teamData.images[imageType];
};

const loadImageUrlPlayerMatchup = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, 'matchup'));
};

exports.loadImageUrlPlayerMatchup = loadImageUrlPlayerMatchup;

const loadImageUrlPlayerResults = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, 'results'));
};

exports.loadImageUrlPlayerResults = loadImageUrlPlayerResults;

const loadImageUrlPlayerResultsGlow = (player, team, league, season, split) => {
  return loadImageUrl(player ? getPlayerImageUrl(player, team, 'resultsGlow') : '');
};

exports.loadImageUrlPlayerResultsGlow = loadImageUrlPlayerResultsGlow;

const loadImageUrlPlayerStarters = (player, team, league, season, split) => {
  return loadImageUrl(player ? getPlayerImageUrl(player, team, 'starters') : '');
};

exports.loadImageUrlPlayerStarters = loadImageUrlPlayerStarters;

const loadImageUrlPlayerVoting = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, 'voting'));
};

exports.loadImageUrlPlayerVoting = loadImageUrlPlayerVoting;

const loadImageUrlTeamLogo = (team, league, season, split) => {
  return loadImageUrl(getTeamImageUrl(team, 'logoFullColor'));
};

exports.loadImageUrlTeamLogo = loadImageUrlTeamLogo;

const loadImageUrlTeamColorLogo = (team, league, season, split) => {
  return loadImageUrl(getTeamImageUrl(team, 'logoTeamColor'));
};

exports.loadImageUrlTeamColorLogo = loadImageUrlTeamColorLogo;

const loadImageUrlTeamTag = (team, league, season, split) => {
  const url = "/leagues/".concat(league, "/").concat(season, "/").concat(split, "/teams/").concat(normalizeName(team), "/assets/tag_brush-gradient_").concat(team.toLowerCase(), ".png");
  return loadImageUrl(url);
};

exports.loadImageUrlTeamTag = loadImageUrlTeamTag;

const preloadImage = async url => await new Promise(resolve => {
  if (!url) {
    resolve();
    return;
  }

  const img = new Image();

  img.onload = function () {
    resolve();
  };

  img.onerror = function () {
    resolve();
  };

  img.src = url;
});

exports.preloadImage = preloadImage;

const preloadImages = async urls => {
  const promises = [];
  urls.forEach(url => {
    if (url) {
      promises.push(preloadImage(url));
    }
  });

  if (promises.length > 0) {
    await Promise.all(promises);
  }
};

exports.preloadImages = preloadImages;

function imageError(ev) {
  ev.target.src = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/dummy_ban.png';
}