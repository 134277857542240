"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamLogo = exports.StyleScorePredictionText = exports.StyleScorePrediction = exports.StylePredictionContainer = exports.StyleCasterName = exports.StyleBottomBar = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _reactSpring = require("react-spring");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const StylePredictionContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StylePredictionContainer",
  componentId: "sc-1j5vs6a-0"
})(["background-image:", ";background-size:cover;background-position:center;width:100%;position:relative;height:", ";margin-top:", ";background-color:#141720;margin-right:10px;"], props => "url(".concat(props.img, ")"), props => props.issingleline ? '400px' : '350px', props => props.issingleline ? '100px' : '0');
exports.StylePredictionContainer = StylePredictionContainer;

const StyleCasterName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleCasterName",
  componentId: "sc-1j5vs6a-1"
})(["width:100%;height:50px;line-height:30px;background-color:#121720;color:#FFFFFF;font-size:30px;font-weight:500;text-align:center;display:flex;justify-content:center;align-items:center;"]);

exports.StyleCasterName = StyleCasterName;
const StyleTeamLogo = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1j5vs6a-2"
})(["background-image:url(", ");background-position:center;background-size:cover;width:100%;height:110px;"], props => props.img);
exports.StyleTeamLogo = StyleTeamLogo;
const StyleScorePredictionText = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleScorePredictionText",
  componentId: "sc-1j5vs6a-3"
})(["line-height:40px;color:#FFFFFF;font-size:30px;font-weight:400;text-align:center;"]);
exports.StyleScorePredictionText = StyleScorePredictionText;

const StyleScorePrediction = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScorePrediction",
  componentId: "sc-1j5vs6a-4"
})(["position:absolute;bottom:5px;width:100%;height:40px;background-color:#121720;overflow:hidden;display:flex;justify-content:center;align-items:center;"]);

exports.StyleScorePrediction = StyleScorePrediction;

const StyleBottomBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBottomBar",
  componentId: "sc-1j5vs6a-5"
})(["position:absolute;bottom:0;width:100%;height:5px;background:linear-gradient(90deg,#f00020 0%,#540035 100%);"]);

exports.StyleBottomBar = StyleBottomBar;