"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleRowTeam = exports.StyleRowScore = exports.StyleRowRank = exports.StyleRowPlayerImage = exports.StyleRowPlayer = exports.StyleRowNameSub = exports.StyleRowNameMain = exports.StyleRowName = exports.StyleRow = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    clipPath: "inset(0 100% 0 0)",
    transition: {
      duration: 300
    }
  },
  show: {
    clipPath: "inset(0 0% 0 0)",
    transition: {
      duration: 300
    }
  }
});

const StyleRow = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "style__StyleRow",
  componentId: "sc-fxdmed-0"
})(["background-color:#1d1d1d;background-image:url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/standings-row-brush.png);display:flex;height:110px;margin-bottom:7px;position:relative;width:100%;", ""], props => props.cssRow);
exports.StyleRow = StyleRow;

const StyleRowRank = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowRank",
  componentId: "sc-fxdmed-1"
})(["align-items:center;background-color:black;color:#dcdcdc;display:flex;font-size:32px;height:100%;justify-content:center;width:60px;", ""], props => props.cssTopRowRank);

exports.StyleRowRank = StyleRowRank;

const StyleRowPlayer = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowPlayer",
  componentId: "sc-fxdmed-2"
})(["width:130px;background-position:center;background-repeat:no-repeat;background-size:cover;background-image:url(", ");height:100%;"], props => props.bgImage);

exports.StyleRowPlayer = StyleRowPlayer;

const StyleRowTeam = _styledComponents.default.img.withConfig({
  displayName: "style__StyleRowTeam",
  componentId: "sc-fxdmed-3"
})(["width:50px;margin:0 20px;"]);

exports.StyleRowTeam = StyleRowTeam;

const StyleRowName = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowName",
  componentId: "sc-fxdmed-4"
})(["align-items:center;color:#a1a1a1;display:flex;font-size:32px;font-weight:600;height:100%;width:150px;", ""], props => props.cssRowName);

exports.StyleRowName = StyleRowName;

const StyleRowNameMain = _styledComponents.default.span.withConfig({
  displayName: "style__StyleRowNameMain",
  componentId: "sc-fxdmed-5"
})(["position:absolute;transform:translateY(20%);"]);

exports.StyleRowNameMain = StyleRowNameMain;

const StyleRowNameSub = _styledComponents.default.span.withConfig({
  displayName: "style__StyleRowNameSub",
  componentId: "sc-fxdmed-6"
})(["position:absolute;transform:translateY(-100%);font-size:25px;"]);

exports.StyleRowNameSub = StyleRowNameSub;

const StyleRowScore = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowScore",
  componentId: "sc-fxdmed-7"
})(["align-items:center;color:white;display:flex;font-size:40px;font-weight:900;height:100%;justify-content:flex-end;padding-right:20px;text-align:right;width:144px;", ""], props => props.cssRowScore);

exports.StyleRowScore = StyleRowScore;

const StyleRowPlayerImage = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRowPlayerImage",
  componentId: "sc-fxdmed-8"
})(["background-position:center;background-repeat:no-repeat;background-size:cover;background-image:url(", ");height:100%;width:130px;"], props => props.bgImage);

exports.StyleRowPlayerImage = StyleRowPlayerImage;