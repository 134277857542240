"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WinCountWrapper = exports.WinCount = exports.VoidGrubContainer = exports.TokenContainer = exports.TeamName = exports.TeamLogo = exports.TeamBox = exports.StyleWrapper = exports.StatNumber = exports.SoulContainer = exports.ScoreBar = exports.MainWrapper = exports.KillCountWrapper = exports.KillCount = exports.IconWrapper = exports.GameTime = exports.GameLight = exports.GameCountContainer = exports.BottomCenterContainer = exports.BottomBarText = exports.BottomBar = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleWrapper",
  componentId: "sc-h5y4v2-0"
})(["display:flex;justify-content:center;position:absolute;left:50%;transform:translate(-50%,0);"]);

exports.StyleWrapper = StyleWrapper;

const TeamBox = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamBox",
  componentId: "sc-h5y4v2-1"
})(["width:60px;height:60px;background-color:#06090d;display:flex;align-items:center;justify-content:center;"]);

exports.TeamBox = TeamBox;

const TeamLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__TeamLogo",
  componentId: "sc-h5y4v2-2"
})(["height:45px;width:45px;object-fit:cover;object-position:center;display:block;background-color:#06090d;"]);

exports.TeamLogo = TeamLogo;

const GameCountContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__GameCountContainer",
  componentId: "sc-h5y4v2-3"
})(["width:60px;height:24px;display:flex;justify-content:center;align-items:center;background:rgb(6,9,13);background:linear-gradient( 0deg,rgba(6,9,13,0.3) 0%,rgba(6,9,13,1) 100% );"]);

exports.GameCountContainer = GameCountContainer;

const GameLight = _styledComponents.default.div.withConfig({
  displayName: "styles__GameLight",
  componentId: "sc-h5y4v2-4"
})(["width:9px;height:9px;border-radius:50%;margin-right:6px;background-color:", ";&:last-of-type{margin-right:0px;}"], props => props.win ? props.teamColor : "#606060");

exports.GameLight = GameLight;

const MainWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__MainWrapper",
  componentId: "sc-h5y4v2-5"
})([""]);

exports.MainWrapper = MainWrapper;

const BottomBar = _styledComponents.default.div.withConfig({
  displayName: "styles__BottomBar",
  componentId: "sc-h5y4v2-6"
})(["display:grid;grid-template-columns:repeat(27,1fr);width:750px;align-items:center;"]);

exports.BottomBar = BottomBar;

const BottomCenterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__BottomCenterContainer",
  componentId: "sc-h5y4v2-7"
})(["grid-column-start:9;grid-column-end:20;display:flex;justify-content:center;align-items:center;"]);

exports.BottomCenterContainer = BottomCenterContainer;

const GameTime = _styledComponents.default.p.withConfig({
  displayName: "styles__GameTime",
  componentId: "sc-h5y4v2-8"
})(["width:auto;padding:0 8px;height:24px;text-align:center;display:flex;align-items:center;justify-content:center;font-family:aktiv-grotesk-ex-medium,sans-serif;color:#f5f5f5;font-size:17px;font-weight:500;background:rgb(6,9,13);background:linear-gradient( 0deg,rgba(6,9,13,0.3) 0%,rgba(6,9,13,1) 100% );opacity:", ";span{position:relative;top:2px;}"], props => props.show ? "1" : "0");

exports.GameTime = GameTime;

const BottomBarText = _styledComponents.default.p.withConfig({
  displayName: "styles__BottomBarText",
  componentId: "sc-h5y4v2-9"
})(["font-family:aktiv-grotesk-ex-medium,sans-serif;color:#f5f5f5;font-size:17px;font-weight:500;position:relative;top:2px;"]);

exports.BottomBarText = BottomBarText;

const TokenContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TokenContainer",
  componentId: "sc-h5y4v2-10"
})(["transition:0.5s ease;opacity:", ";height:24px;width:24px;display:flex;align-items:center;justify-content:center;background:", ";background:", ";img{height:18px;object-fit:contain;width:16px;margin:0 3px;}"], props => props.token ? "1" : "0", props => props.token ? "rgb(6,9,13)" : "transparent", props => props.token ? "linear-gradient(0deg, rgba(6,9,13,0.3) 0%, rgba(6,9,13,1) 100%)" : "transparent");

exports.TokenContainer = TokenContainer;

const VoidGrubContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__VoidGrubContainer",
  componentId: "sc-h5y4v2-11"
})(["height:24px;column-gap:4px;display:flex;align-items:center;background:rgb(6,9,13);background:linear-gradient( 0deg,rgba(6,9,13,0.3) 0%,rgba(6,9,13,1) 100% );img{height:16px;width:16px;object-fit:contain;}&.first{padding-left:16px;padding-right:8px;}&.second{padding-left:8px;padding-right:16px;}"]);

exports.VoidGrubContainer = VoidGrubContainer;

const SoulContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__SoulContainer",
  componentId: "sc-h5y4v2-12"
})(["transition:0.5s ease;width:70px;height:24px;display:flex;justify-content:center;align-items:center;opacity:", ";background:", ";img{height:18px;object-fit:contain;width:16px;}&:first-of-type{grid-column-start:1;grid-column-end:4;}&:last-of-type{grid-column-start:25;grid-column-end:28;margin-left:auto;}"], props => props.token ? "1" : "0", props => props.tokenColor || "transparent");

exports.SoulContainer = SoulContainer;

const ScoreBar = _styledComponents.default.div.withConfig({
  displayName: "styles__ScoreBar",
  componentId: "sc-h5y4v2-13"
})(["width:750px;background-color:#06090d;height:60px;display:grid;grid-template-columns:repeat(27,1fr);padding:0px;align-items:center;"]);

exports.ScoreBar = ScoreBar;

const WinCountWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__WinCountWrapper",
  componentId: "sc-h5y4v2-14"
})(["color:#f5f5f5;text-align:center;display:grid;min-width:42px;&:first-of-type{grid-column-start:1;grid-column-end:3;margin-left:auto;}&:last-of-type{grid-column-start:26;grid-column-end:28;display:grid;margin-right:auto;}"]);

exports.WinCountWrapper = WinCountWrapper;

const TeamName = _styledComponents.default.p.withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-h5y4v2-15"
})(["font-family:aktiv-grotesk-bold,sans-serif;font-size:18px;font-weight:700;height:13px;margin-bottom:", ";"], props => props.isPlayoffs ? "7px" : "2px");

exports.TeamName = TeamName;

const WinCount = _styledComponents.default.p.withConfig({
  displayName: "styles__WinCount",
  componentId: "sc-h5y4v2-16"
})(["font-family:aktiv-grotesk-regular,sans-serif;font-weight:400;font-size:14px;margin-top:7px;letter-spacing:0.28px;height:12px;"]);

exports.WinCount = WinCount;

const IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-h5y4v2-17"
})(["display:flex;&.tower-stat img{width:19px;height:26px;padding-bottom:1px;}&.gold-stat img{margin-top:3px;width:24px;height:22px;}&.tower-stat,&.gold-stat{margin:0 auto;}&.tower-stat.first{grid-column-start:3;grid-column-end:7;}&.gold-stat.first{grid-column-start:7;grid-column-end:11;}&.gold-stat.second{grid-column-start:18;grid-column-end:22;}&.tower-stat.second{grid-column-start:22;grid-column-end:26;}"]);

exports.IconWrapper = IconWrapper;

const StatNumber = _styledComponents.default.p.withConfig({
  displayName: "styles__StatNumber",
  componentId: "sc-h5y4v2-18"
})(["font-family:dharma-gothic-e-bold,sans-serif;font-size:34px;font-weight:700;height:24px;margin-bottom:5px;margin-left:10px;letter-spacing:0.68px;color:", ";"], props => props.side === "left" ? "#0078ff" : "#dd3217");

exports.StatNumber = StatNumber;

const KillCountWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__KillCountWrapper",
  componentId: "sc-h5y4v2-19"
})(["display:flex;grid-column-start:11;grid-column-end:18;margin:0 auto;.center-icon{width:31px;height:31px;margin:3px 22px;}"]);

exports.KillCountWrapper = KillCountWrapper;

const KillCount = _styledComponents.default.p.withConfig({
  displayName: "styles__KillCount",
  componentId: "sc-h5y4v2-20"
})(["font-family:dharma-gothic-e-bold,sans-serif;font-size:48px;font-weight:700;height:34px;margin-top:-3px;color:", ";"], props => props.side === "left" ? "#0078ff" : "#dd3217");

exports.KillCount = KillCount;