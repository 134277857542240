"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SingleTableContainer = exports.MultipleTableContainer = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-j0h3pi-0"
})(["display:flex;position:absolute;top:0;left:0;color:white;font-weight:100;margin:auto;width:100%;height:105%;font-family:inherit;font-weight:700;align-items:center;padding:170px 50px 50px 50px;transition:padding 300ms linear;", ""], props => props.cssStanding);

exports.Base = Base;

const SingleTableContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__SingleTableContainer",
  componentId: "sc-j0h3pi-1"
})(["display:flex;flex-direction:column;width:100%;height:100%;justify-content:center;"]);

exports.SingleTableContainer = SingleTableContainer;

const MultipleTableContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__MultipleTableContainer",
  componentId: "sc-j0h3pi-2"
})(["display:grid;grid-template-columns:repeat(2,1fr);grid-column-gap:30px;grid-row-gap:20px;width:100%;height:", "px;"], props => props.height);

exports.MultipleTableContainer = MultipleTableContainer;