"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Timer = exports.TimeContainer = exports.TeamName = exports.TeamLogoContainer = exports.TeamLogo = exports.Score = exports.RightTeamContainer = exports.NextGameTitle = exports.LeftTeamContainer = exports.Container = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTools = require("styled-tools");

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const BaseAnimation = _reactPose.default.div({
  hide: {
    y: '150px',
    transition: {
      duration: 500
    }
  },
  display: {
    y: '0px',
    transition: {
      duration: 500
    }
  }
});

const Base = (0, _styledComponents.default)(BaseAnimation).withConfig({
  displayName: "style__Base",
  componentId: "sc-136pj57-0"
})(["position:absolute;font-weight:700;width:1920px;background:transparent;overflow:hidden;bottom:0;z-index:3;"]);
exports.Base = Base;

const Container = _styledComponents.default.div.withConfig({
  displayName: "style__Container",
  componentId: "sc-136pj57-1"
})(["display:flex;flex-direction:row;justify-content:space-evenly;height:150px;border-width:8px 0 0 0;border-style:solid;border-image:linear-gradient(to right,", ",", ",", ") 10;overflow:hidden;background-color:", ";", ""], (0, _styledTools.prop)("borderGradientColorLeft", '#000000'), (0, _styledTools.prop)("borderGradientColorBase", '#000000'), (0, _styledTools.prop)("borderGradientColorRight", '#000000'), props => props.backgroundColor, props => props.cssBottomBar);

exports.Container = Container;

const LeftTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "style__LeftTeamContainer",
  componentId: "sc-136pj57-2"
})(["color:white;width:100%;font-size:56px;display:flex;flex-direction:row;position:relative;align-items:center;justify-content:center;text-shadow:0px 4px 4px black;"]);

exports.LeftTeamContainer = LeftTeamContainer;

const RightTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "style__RightTeamContainer",
  componentId: "sc-136pj57-3"
})(["color:white;font-size:56px;display:flex;flex-direction:row;width:100%;position:relative;align-items:center;justify-content:center;text-shadow:0px 4px 4px black;"]);

exports.RightTeamContainer = RightTeamContainer;

const TeamName = _styledComponents.default.div.withConfig({
  displayName: "style__TeamName",
  componentId: "sc-136pj57-4"
})(["z-index:5;font-weight:700;color:#ffffff;", ""], props => props.css);

exports.TeamName = TeamName;

const Score = _styledComponents.default.div.withConfig({
  displayName: "style__Score",
  componentId: "sc-136pj57-5"
})(["margin-right:50px;margin-left:50px;z-index:5;font-size:85px;"]);

exports.Score = Score;

const TimeContainer = _styledComponents.default.div.withConfig({
  displayName: "style__TimeContainer",
  componentId: "sc-136pj57-6"
})(["font-size:36px;text-align:center;width:100%;height:100%;align-self:center;display:flex;flex-direction:column;justify-content:space-around;"]);

exports.TimeContainer = TimeContainer;

const NextGameTitle = _styledComponents.default.div.withConfig({
  displayName: "style__NextGameTitle",
  componentId: "sc-136pj57-7"
})(["font-size:40px;font-weight:700;letter-spacing:1px;position:relative;flex:0 0 40px;color:#ffffff;", ""], props => props.css);

exports.NextGameTitle = NextGameTitle;

const Timer = _styledComponents.default.div.withConfig({
  displayName: "style__Timer",
  componentId: "sc-136pj57-8"
})(["font-size:70px;width:100%;flex:0 0 80px;color:#ffffff;font-weight:700;letter-spacing:2px;height:70px;line-height:1em;", ""], props => props.css);

exports.Timer = Timer;

const TeamLogo = _styledComponents.default.div.withConfig({
  displayName: "style__TeamLogo",
  componentId: "sc-136pj57-9"
})(["position:absolute;right:", ";left:", ";z-index:2;img{opacity:0.7;width:510px;transform:", ";}"], props => props.left ? 'unset' : '0', props => props.left ? '0' : 'unset', props => props.left ? 'translate(-100px, 15px)' : 'translate(100px, 15px)');

exports.TeamLogo = TeamLogo;

const TeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "style__TeamLogoContainer",
  componentId: "sc-136pj57-10"
})(["height:100%;width:100%;position:absolute;background-image:", ";"], props => "linear-gradient(to ".concat(props.direction, ", ").concat(props.color, ", rgba(0, 0, 0, 0))"));

exports.TeamLogoContainer = TeamLogoContainer;