"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleStatValue = exports.StyleStatHeader = exports.StyleStat = exports.StyleMain = exports.StyleFirstPick = exports.StyleContent = exports.StyleChampionIcon = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMain = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-1g0yaya-0"
})(["width:170px;height:230px;position:absolute;bottom:0px;overflow:hidden;left:", "px;transform:", ";"], props => props.position.left || 0, props => props.team === 'team100' ? "translateX(-110%)" : "translateX(110%)");
exports.StyleMain = StyleMain;
const StyleContent = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleContent",
  componentId: "sc-1g0yaya-1"
})(["width:100%;height:100%;background-color:#141720;overflow:hidden;display:flex;flex-direction:column;align-items:center;padding:20px;position:relative;"]);
exports.StyleContent = StyleContent;

const StyleChampionIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionIcon",
  componentId: "sc-1g0yaya-2"
})(["margin-bottom:20px;img{width:50px;height:50px;}"]);

exports.StyleChampionIcon = StyleChampionIcon;

const StyleStat = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStat",
  componentId: "sc-1g0yaya-3"
})(["width:100%;text-align:left;color:#F5F5F5;justify-content:space-between;display:flex;margin-bottom:15px;"]);

exports.StyleStat = StyleStat;

const StyleFirstPick = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleFirstPick",
  componentId: "sc-1g0yaya-4"
})(["display:flex;margin-bottom:5px;justify-content:center;align-items:center;height:100%;width:116px;color:", ";"], props => props.color);

exports.StyleFirstPick = StyleFirstPick;

const StyleStatHeader = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleStatHeader",
  componentId: "sc-1g0yaya-5"
})(["font-weight:400;font-size:14px;line-height:14px;display:flex;align-items:flex-end;font-family:aktiv-grotesk-ex-regular;"]);

exports.StyleStatHeader = StyleStatHeader;

const StyleStatValue = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleStatValue",
  componentId: "sc-1g0yaya-6"
})(["font-weight:700;font-size:30px;line-height:28px;font-family:aktiv-grotesk-con-bold;"]);

exports.StyleStatValue = StyleStatValue;