"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamRowCell = exports.TeamRow = exports.TeamName = exports.StyleStandingTitle = exports.StyleBottomBar = exports.StandingContainer = exports.Score = exports.Rank = exports.Logo = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StandingContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StandingContainer",
  componentId: "sc-1vu0sq6-0"
})(["position:relative;display:flex;flex-direction:column;justify-content:space-evenly;max-height:680px;width:100%;padding:", ";overflow:hidden;transition:opacity 500ms linear;", " opacity:", ";"], props => props.tablesCount <= 2 ? '20px 30px' : '10px 20px', props => props.background && "background: ".concat(props.background, ";"), props => props.show ? 1 : 0);

exports.StandingContainer = StandingContainer;

const StyleStandingTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStandingTitle",
  componentId: "sc-1vu0sq6-1"
})(["font-family:dharma-gothic-e,sans-serif;font-weight:700;font-size:80px;line-height:80px;color:#8de8f9;"]);

exports.StyleStandingTitle = StyleStandingTitle;
const TeamRow = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__TeamRow",
  componentId: "sc-1vu0sq6-2"
})(["display:grid;grid-template-columns:1fr 3fr 19fr 3fr;width:100%;z-index:2;min-height:66px;height:", "px;overflow:hidden;align-items:stretch;font-family:proxima-nova,sans-serif;font-weight:700;font-size:22px;color:#ffffff;", " ", ""], props => props.tablesCount <= 2 ? '86' : '66', props => !props.last && "border-bottom: 1px #ffffffB3 solid;", props => props.csscell);
exports.TeamRow = TeamRow;

const TeamRowCell = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamRowCell",
  componentId: "sc-1vu0sq6-3"
})(["align-items:center;display:flex;flex-grow:1;justify-content:center;position:relative;"]);

exports.TeamRowCell = TeamRowCell;
const Rank = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Rank",
  componentId: "sc-1vu0sq6-4"
})(["", ""], props => props.cssRankCell);
exports.Rank = Rank;
const Logo = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Logo",
  componentId: "sc-1vu0sq6-5"
})(["display:flex;img{object-fit:contain;width:60px;height:60px;}", ""], props => props.cssLogoCell);
exports.Logo = Logo;
const TeamName = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-1vu0sq6-6"
})(["justify-content:left;text-transform:uppercase;", ""], props => props.cssNameCell);
exports.TeamName = TeamName;
const Score = (0, _styledComponents.default)(TeamRowCell).withConfig({
  displayName: "styles__Score",
  componentId: "sc-1vu0sq6-7"
})(["", ""], props => props.cssScoreCell);
exports.Score = Score;

const StyleBottomBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBottomBar",
  componentId: "sc-1vu0sq6-8"
})(["position:absolute;bottom:0;left:0;right:0;height:15px;background:linear-gradient(to right,#15ace2,#8de8f9);"]);

exports.StyleBottomBar = StyleBottomBar;