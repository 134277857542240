"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styleof5GridSingleGamesContainer = exports.StyleWinTextContainer = exports.StyleWin = exports.StyleWeekTitle = exports.StyleUpcomingGameText = exports.StyleUpcomingGameInfo = exports.StyleUpcomingGameContainerBo5Finish = exports.StyleUpcomingGameContainer = exports.StyleTimer = exports.StyleSidebarText = exports.StyleSidebarSchedule = exports.StyleScheduleRow = exports.StyleScheduleList = exports.StyleRightSideContainer = exports.StyleResultsGameText = exports.StyleNextMatchupVS = exports.StyleNextMatchupText = exports.StyleNextMatchupTeamScore = exports.StyleNextMatchupTeamName = exports.StyleNextMatchupTeamLogo = exports.StyleNextMatchupTeamContainer = exports.StyleNextMatchupHeading = exports.StyleNextMatchupContainer = exports.StyleNextMatchupArrow = exports.StyleNextMatchup = exports.StyleNextGame = exports.StyleLogoImg = exports.StyleLeftSideContainer = exports.StyleLeagueLogoBo5Finish = exports.StyleLeagueLogo = exports.StyleGameTime = exports.StyleCenterContainer = exports.StyleBof5ScoreSingleMatch = exports.StyleBof5Score = exports.StyleBof5MatchName = exports.StyleBof5MatchContainer = exports.StyleBof5Logo = exports.StyleBof5GridSingleMatch = exports.StyleBof5Grid = exports.StyleBof3MatchWrapper = exports.StyleBo3TeamName = exports.StyleBo3ScoreContainer = exports.StyleBo3ScoreArrow = exports.StyleBo3Score = exports.StyleBo3MatchName = exports.StyleBo3MatchContainer = exports.StyleBo3Logo = exports.StyleBo3Grid = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleNextMatchup = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleNextMatchup",
  componentId: "sc-a7z9x-0"
})(["position:absolute;top:70px;right:70px;"]);
exports.StyleNextMatchup = StyleNextMatchup;

const StyleNextMatchupHeading = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNextMatchupHeading",
  componentId: "sc-a7z9x-1"
})(["display:flex;"]);

exports.StyleNextMatchupHeading = StyleNextMatchupHeading;

const StyleNextMatchupArrow = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleNextMatchupArrow",
  componentId: "sc-a7z9x-2"
})(["width:17px;height:19px;object-fit:contain;transform:rotate(180deg);margin-right:15px;"]);

exports.StyleNextMatchupArrow = StyleNextMatchupArrow;

const StyleNextMatchupText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNextMatchupText",
  componentId: "sc-a7z9x-3"
})(["font-size:24px;text-transform:uppercase;font-family:aktiv-grotesk-ex-medium;font-weight:600;color:#F0F0F0;margin-bottom:20px;"]);

exports.StyleNextMatchupText = StyleNextMatchupText;

const StyleNextMatchupContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNextMatchupContainer",
  componentId: "sc-a7z9x-4"
})(["display:flex;"]);

exports.StyleNextMatchupContainer = StyleNextMatchupContainer;

const StyleNextMatchupTeamLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleNextMatchupTeamLogo",
  componentId: "sc-a7z9x-5"
})(["width:120px;height:120px;"]);

exports.StyleNextMatchupTeamLogo = StyleNextMatchupTeamLogo;

const StyleNextMatchupTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNextMatchupTeamContainer",
  componentId: "sc-a7z9x-6"
})(["background-color:#06090D;width:455px;height:120px;padding:24px 30px;&:last-of-type p{justify-content:flex-end;margin-left:auto;}", ""], props => props.isPlayoffs && "\n    display: flex;\n    align-items: center;\n  ");

exports.StyleNextMatchupTeamContainer = StyleNextMatchupTeamContainer;

const StyleNextMatchupTeamName = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleNextMatchupTeamName",
  componentId: "sc-a7z9x-7"
})(["text-transform:uppercase;font-family:dharma-gothic-e-bold;font-weight:700;font-size:54px;height:40px;margin-bottom:9px;display:flex;align-items:center;color:#F5F5F5;", ""], props => props.isPlayoffs && "\n    margin-top: auto;\n    margin-bottom: auto;\n    top: 2px;\n    position: relative;\n  ");

exports.StyleNextMatchupTeamName = StyleNextMatchupTeamName;

const StyleNextMatchupTeamScore = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleNextMatchupTeamScore",
  componentId: "sc-a7z9x-8"
})(["font-family:aktiv-grotesk-ex-medium;font-weight:600;font-size:28px;letter-spacing:0.56px;color:#F5F5F5;height:25px;display:flex;align-items:center;"]);

exports.StyleNextMatchupTeamScore = StyleNextMatchupTeamScore;

const StyleNextMatchupVS = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleNextMatchupVS",
  componentId: "sc-a7z9x-9"
})(["background-color:#F5F5F5;width:110px;height:120px;font-size:80px;font-family:dharma-gothic-e-bold;font-weight:700;color:#06090D;display:flex;justify-content:center;align-items:center;& span{height:57px;display:flex;align-items:center;margin-top:6px;}"]);

exports.StyleNextMatchupVS = StyleNextMatchupVS;
const StyleSidebarSchedule = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleSidebarSchedule",
  componentId: "sc-a7z9x-10"
})(["position:absolute;top:0;width:558px;height:1080px;padding:54px;background:linear-gradient(60deg,#540035 0%,#f00020 100%);box-shadow:5px -6px 26px -10px black;font-style:normal;display:flex;flex-direction:column;justify-content:space-between;", ""], props => props.stylesidebarschedule || '');
exports.StyleSidebarSchedule = StyleSidebarSchedule;

const StyleWeekTitle = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleWeekTitle",
  componentId: "sc-a7z9x-11"
})(["padding:0 25px 26px;font-size:40px;text-transform:uppercase;", ";&.new-mode-week-title{padding:0px 0px 28px 0px;line-height:1.21;letter-spacing:0.24px;}"], props => props.StyleWeekTitle || '');

exports.StyleWeekTitle = StyleWeekTitle;

const StyleScheduleList = _styledComponents.default.ul.withConfig({
  displayName: "styles__StyleScheduleList",
  componentId: "sc-a7z9x-12"
})(["width:450px;padding:25px;background-color:#1417204d;", " ", ""], props => props.StyleScheduleList || '', props => props.isFlexLayout ? "\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n  " : "");

exports.StyleScheduleList = StyleScheduleList;

const StyleScheduleRow = _styledComponents.default.li.withConfig({
  displayName: "styles__StyleScheduleRow",
  componentId: "sc-a7z9x-13"
})(["display:grid;grid-template-columns:1fr 2fr 1fr;align-items:center;text-align:center;height:75px;padding:0 10px;opacity:", ";", ";", ";", ""], props => props.transparent ? '0.3' : '1', props => props.StyleScheduleRow || '', props => props.StyleScheduleRowBo5 || '', props => props.isFlexLayout ? "\n    height: 100%;\n  " : "");

exports.StyleScheduleRow = StyleScheduleRow;

const StyleLeftSideContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLeftSideContainer",
  componentId: "sc-a7z9x-14"
})(["opacity:", ";", ";"], props => props.transparent ? '0.3' : '1', props => props.StyleLeftSideContainer || '');

exports.StyleLeftSideContainer = StyleLeftSideContainer;

const StyleCenterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleCenterContainer",
  componentId: "sc-a7z9x-15"
})(["position:relative;bottom:-2px;display:flex;justify-content:center;font-size:33px;color:white;opacity:", ";", ";"], props => props.transparent ? '0.3' : '1', props => props.StyleCenterContainer || '');

exports.StyleCenterContainer = StyleCenterContainer;

const StyleWinTextContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleWinTextContainer",
  componentId: "sc-a7z9x-16"
})(["", " ", ""], props => props.StyleWinTextContainer || '', props => props.isFlexLayout ? "\n    font-size: 80px;\n  " : "");

exports.StyleWinTextContainer = StyleWinTextContainer;

const StyleWin = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleWin",
  componentId: "sc-a7z9x-17"
})(["margin-left:10px;", ""], props => props.StyleWin || '');

exports.StyleWin = StyleWin;

const StyleRightSideContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRightSideContainer",
  componentId: "sc-a7z9x-18"
})(["opacity:", ";", ";& img{margin-left:auto;}"], props => props.transparent ? '0.3' : '1', props => props.StyleRightSideContainer || '');

exports.StyleRightSideContainer = StyleRightSideContainer;

const StyleLogoImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleLogoImg",
  componentId: "sc-a7z9x-19"
})(["width:60px;height:60px;display:flex;opacity:", ";", ";", ";", ";", ";", ""], props => props.skip || props.isFinished && !props.isWinner ? props.logoLoss : '1', props => props.StyleLogoImg ? props.StyleLogoImg : '', props => props.StyleLogoImgBo5 ? props.StyleLogoImgBo5 : '', props => props.StyleLogoImgLeft ? props.StyleLogoImgLeft : '', props => props.StyleLogoImgRight ? props.StyleLogoImgRight : '', props => props.isFlexLayout ? "\n    width: 90px;\n    height: 90px;\n  " : "");

exports.StyleLogoImg = StyleLogoImg;

const StyleNextGame = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleNextGame",
  componentId: "sc-a7z9x-20"
})(["color:#8de8f9;line-height:35px;", ";", ";", ""], props => props.StyleNextGame || '', props => props.StyleNextGameBo5 || '', props => props.isFlexLayout ? "\n    font-size: 80px;\n  " : "");

exports.StyleNextGame = StyleNextGame;

const StyleGameTime = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleGameTime",
  componentId: "sc-a7z9x-21"
})(["", ";", ";", ""], props => props.StyleGameTime || '', props => props.StyleGameTimeBo5 || '', props => props.isFlexLayout ? "\n    font-size: 80px;\n  " : "");

exports.StyleGameTime = StyleGameTime;

const StyleUpcomingGameContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleUpcomingGameContainer",
  componentId: "sc-a7z9x-22"
})(["display:grid;grid-template-columns:147px 1fr;padding:0 25px;margin-left:-33px;font-weight:700;", " &.new-mode{display:block;margin-left:0px;font-size:24px;line-height:1.21;letter-spacing:0.24px;}"], props => props.StyleUpcomingGameContainer ? props.StyleUpcomingGameContainer : '');

exports.StyleUpcomingGameContainer = StyleUpcomingGameContainer;

const StyleLeagueLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleLeagueLogo",
  componentId: "sc-a7z9x-23"
})(["height:auto;width:146px;", ""], props => props.StyleLeagueLogo || '');

exports.StyleLeagueLogo = StyleLeagueLogo;

const StyleUpcomingGameText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleUpcomingGameText",
  componentId: "sc-a7z9x-24"
})(["font-weight:500;text-transform:uppercase;padding-left:5px;", ""], props => props.StyleUpcomingGameText || '');

exports.StyleUpcomingGameText = StyleUpcomingGameText;

const StyleSidebarText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleSidebarText",
  componentId: "sc-a7z9x-25"
})(["font-weight:400;text-transform:uppercase;position:relative;left:45px;", " &.new-mode-timer{margin-bottom:0px;left:0px;line-height:1.21;letter-spacing:0.24px;color:#F5F5F5;}"], props => props.StyleSidebarText || '');

exports.StyleSidebarText = StyleSidebarText;

const StyleTimer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTimer",
  componentId: "sc-a7z9x-26"
})(["line-height:100px;font-weight:700;", " &.new-mode-timer.text{font-size:200px;line-height:165px;letter-spacing:2px;font-weight:700;color:#F5F5F5;}&.new-mode-timer div{font-size:220px;line-height:180px;position:initial;color:#F5F5F5;}"], props => props.StyleTimer || '');

exports.StyleTimer = StyleTimer;

const StyleUpcomingGameInfo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleUpcomingGameInfo",
  componentId: "sc-a7z9x-27"
})(["position:relative;bottom:-8px;display:flex;flex-direction:column;justify-content:center;font-size:22px;color:white;", ""], props => props.StyleUpcomingGameInfo || '');

exports.StyleUpcomingGameInfo = StyleUpcomingGameInfo;

const StyleBof3MatchWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof3MatchWrapper",
  componentId: "sc-a7z9x-28"
})(["height:100%;border-bottom:1px solid #FFFFFF;display:flex;flex-direction:column;justify-content:center;&:last-of-type{border-bottom:none;}"]);

exports.StyleBof3MatchWrapper = StyleBof3MatchWrapper;

const StyleBo3MatchContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBo3MatchContainer",
  componentId: "sc-a7z9x-29"
})(["width:450px;border-top:2px solid #F5F5F5;&:last-of-type{border-bottom:2px solid #F5F5F5;}"]);

exports.StyleBo3MatchContainer = StyleBo3MatchContainer;

const StyleBo3MatchName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBo3MatchName",
  componentId: "sc-a7z9x-30"
})(["text-transform:uppercase;font-family:aktiv-grotesk-ex-regular,sans-serif;font-size:22px;color:#F5F5F5;margin-top:30px;margin-bottom:20px;"]);

exports.StyleBo3MatchName = StyleBo3MatchName;

const StyleBo3Grid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBo3Grid",
  componentId: "sc-a7z9x-31"
})(["display:grid;grid-template-columns:1fr 2fr 1fr;grid-column-gap:33px;margin-bottom:30px;"]);

exports.StyleBo3Grid = StyleBo3Grid;

const StyleBo3Logo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleBo3Logo",
  componentId: "sc-a7z9x-32"
})(["width:100%;max-width:88px;"]);

exports.StyleBo3Logo = StyleBo3Logo;

const StyleBo3TeamName = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleBo3TeamName",
  componentId: "sc-a7z9x-33"
})(["font-family:dharma-gothic-e-bold;font-weight:700;font-size:100px;letter-spacing:1px;color:#F5F5F5;line-height:0px;align-self:center;margin-top:11px;"]);

exports.StyleBo3TeamName = StyleBo3TeamName;

const StyleBo3ScoreContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBo3ScoreContainer",
  componentId: "sc-a7z9x-34"
})(["display:flex;margin-left:auto;justify-content:space-between;align-items:center;width:89px;"]);

exports.StyleBo3ScoreContainer = StyleBo3ScoreContainer;

const StyleBo3ScoreArrow = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleBo3ScoreArrow",
  componentId: "sc-a7z9x-35"
})(["width:19px;height:22px;object-fit:contain;opacity:", ";"], props => props.isWinner ? '1' : '0');

exports.StyleBo3ScoreArrow = StyleBo3ScoreArrow;

const StyleBo3Score = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleBo3Score",
  componentId: "sc-a7z9x-36"
})(["font-family:dharma-gothic-e-bold;font-weight:700;font-size:100px;letter-spacing:1px;color:#F5F5F5;line-height:0px;align-self:center;margin-top:11px;"]);

exports.StyleBo3Score = StyleBo3Score;

const StyleBof5MatchContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5MatchContainer",
  componentId: "sc-a7z9x-37"
})(["padding:15px 0;:last-of-type{border-bottom:none;margin-bottom:-20px;}", ""], props => props.StyleBof5MatchContainer || '');

exports.StyleBof5MatchContainer = StyleBof5MatchContainer;

const StyleBof5MatchName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5MatchName",
  componentId: "sc-a7z9x-38"
})(["text-align:center;font-size:30px;font-weight:600;margin-top:8px;font-weight:700;", " ", ""], props => props.StyleBof5MatchName || '', props => props.second ? 'margin-top: 32px;' : '');

exports.StyleBof5MatchName = StyleBof5MatchName;

const StyleBof5Grid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5Grid",
  componentId: "sc-a7z9x-39"
})(["display:grid;grid-template-columns:3fr 1fr 3fr;justify-content:center;padding:0 30px;margin:15px 0 20px 0;", ""], props => props.StyleBof5Grid || '');

exports.StyleBof5Grid = StyleBof5Grid;

const Styleof5GridSingleGamesContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__Styleof5GridSingleGamesContainer",
  componentId: "sc-a7z9x-40"
})(["padding-bottom:20px;", ";"], props => props.Styleof5GridSingleGamesContainer || '');

exports.Styleof5GridSingleGamesContainer = Styleof5GridSingleGamesContainer;

const StyleBof5GridSingleMatch = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5GridSingleMatch",
  componentId: "sc-a7z9x-41"
})(["display:grid;grid-template-columns:1fr 2fr 1fr;justify-content:center;margin:30px 0;padding:0 10px;", ""], props => props.StyleBof5GridSingleMatch || '');

exports.StyleBof5GridSingleMatch = StyleBof5GridSingleMatch;

const StyleBof5Logo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleBof5Logo",
  componentId: "sc-a7z9x-42"
})(["width:145px;height:145px;display:flex;margin:0 auto;opacity:", ";", ""], props => props.skip || props.isFinished && !props.isWinner ? '0.3' : '1', props => props.StyleBof5Logo ? props.StyleBof5Logo : '');

exports.StyleBof5Logo = StyleBof5Logo;

const StyleBof5Score = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5Score",
  componentId: "sc-a7z9x-43"
})(["display:flex;justify-content:center;font-weight:700;font-size:40px;padding:9px 0;opacity:", ";", " ", ""], props => props.isFinished && !props.isWinner ? '0.3' : '1', props => props.StyleBof5Score ? props.StyleBof5Score : '', props => !props.isLive ? props.StyleBof5ScoreNotLive ? props.StyleBof5ScoreNotLive : 'color: #f2f2f2;' : ''); // TODO: StyleBof5ScoreNotLive doesn't work.


exports.StyleBof5Score = StyleBof5Score;

const StyleBof5ScoreSingleMatch = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBof5ScoreSingleMatch",
  componentId: "sc-a7z9x-44"
})(["display:flex;justify-content:center;font-weight:700;font-size:90px;margin:20px 0;&:first-of-type,&:last-of-type{width:85px;}&:last-of-type{margin-left:auto;}opacity:", ";", ";", ";"], props => props.isFinished && !props.isWinner ? '1' : '1', props => props.StyleBof5ScoreSingleMatch || '', props => props.isFinished && props.isWinner && props.StyleBof5ScoreSingleMatchWinner ? props.StyleBof5ScoreSingleMatchWinner : '');

exports.StyleBof5ScoreSingleMatch = StyleBof5ScoreSingleMatch;

const StyleUpcomingGameContainerBo5Finish = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleUpcomingGameContainerBo5Finish",
  componentId: "sc-a7z9x-45"
})(["display:grid;grid-template-columns:147px 1fr;padding:0 25px;margin-left:-33px;align-items:center;", " &.new-mode{display:block;margin-left:0px;font-size:24px;line-height:1.21;letter-spacing:0.24px;padding:0px;}"], props => props.StyleUpcomingGameContainerBo5Finish ? props.StyleUpcomingGameContainerBo5Finish : '');

exports.StyleUpcomingGameContainerBo5Finish = StyleUpcomingGameContainerBo5Finish;

const StyleLeagueLogoBo5Finish = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleLeagueLogoBo5Finish",
  componentId: "sc-a7z9x-46"
})(["height:180px;width:147px;", ";"], props => props.StyleLeagueLogoBo5Finish || '');

exports.StyleLeagueLogoBo5Finish = StyleLeagueLogoBo5Finish;

const StyleResultsGameText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleResultsGameText",
  componentId: "sc-a7z9x-47"
})(["font-weight:500;text-transform:uppercase;padding-left:0px;top:0px;margin-left:30px;", ";&.new-mode-timer{font-size:200px;line-height:165px;letter-spacing:2px;font-weight:700;padding:0px;color:#F5F5F5;}"], props => props.StyleResultsGameText || '');

exports.StyleResultsGameText = StyleResultsGameText;