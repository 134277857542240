"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleSummoner = exports.StyleProgressionSummoner = exports.StyleProgressionContainer = exports.StyleProgression = exports.StyleMainContainer = exports.StyleLevel = exports.StyleDataGrid = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMainContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-1vyjcj4-0"
})(["width:100%;margin:3px 10px 0 10px;font-family:\"futura-pt\",sans-serif;", ";"], props => props.stylemaincontainer ? props.stylemaincontainer : '');
exports.StyleMainContainer = StyleMainContainer;

const StyleDataGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDataGrid",
  componentId: "sc-1vyjcj4-1"
})(["display:grid;grid-template-columns:40px 50px 190px;margin:auto;img{width:30px;height:30px;margin:auto;}", ";"], props => props.StyleDataGrid ? props.StyleDataGrid : '');

exports.StyleDataGrid = StyleDataGrid;

const StyleLevel = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLevel",
  componentId: "sc-1vyjcj4-2"
})(["font-size:34px;display:flex;justify-content:center;color:", ";font-family:dharma-gothic-e-bold;", ";"], props => props.color || '#FFFFFF', props => props.StyleLevel ? props.StyleLevel : '');

exports.StyleLevel = StyleLevel;

const StyleProgressionSummoner = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleProgressionSummoner",
  componentId: "sc-1vyjcj4-3"
})(["display:grid;width:100%;padding-left:6px;padding-right:6px;", ";"], props => props.StyleProgressionSummoner ? props.StyleProgressionSummoner : '');

exports.StyleProgressionSummoner = StyleProgressionSummoner;

const StyleSummoner = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleSummoner",
  componentId: "sc-1vyjcj4-4"
})(["display:flex;align-items:flex-end;font-size:16px;color:#F5F5F5;width:100%;text-align:left;text-transform:uppercase;margin-bottom:3px;font-family:aktiv-grotesk-ex-bold;", ";"], props => props.StyleSummoner ? props.StyleSummoner : '');

exports.StyleSummoner = StyleSummoner;

const StyleProgressionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleProgressionContainer",
  componentId: "sc-1vyjcj4-5"
})(["position:relative;height:3px;width:100%;background-color:#343434;margin-bottom:5px;", ";"], props => props.StyleProgressionContainer ? props.StyleProgressionContainer : '');

exports.StyleProgressionContainer = StyleProgressionContainer;

const StyleProgression = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleProgression",
  componentId: "sc-1vyjcj4-6"
})(["position:relative;height:3px;width:", "%;background-color:", ";", ";"], props => props.progress || '0', props => props.color, props => props.StyleProgression ? props.StyleProgression : '');

exports.StyleProgression = StyleProgression;