"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTopRowRank = exports.StyleTopRowPlayerImage = exports.StyleTopRowPlayer = exports.StyleTopRowInfoScore = exports.StyleTopRowInfoName = exports.StyleTopRowInfoLogo = exports.StyleTopRowInfo = exports.StyleTopRow = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleTopRow = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTopRow",
  componentId: "sc-un6rd7-0"
})(["display:flex;height:256px;margin-bottom:7px;position:relative;width:100%;z-index:5;"]);

exports.StyleTopRow = StyleTopRow;

const StyleTopRowRank = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTopRowRank",
  componentId: "sc-un6rd7-1"
})(["align-items:center;background-color:black;color:#dcdcdc;display:flex;font-size:45px;font-weight:400;height:100%;justify-content:center;width:60px;", ""], props => props.cssTopRowRank);

exports.StyleTopRowRank = StyleTopRowRank;

const StyleTopRowPlayer = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTopRowPlayer",
  componentId: "sc-un6rd7-2"
})(["background-position:-380px -40px;background-repeat:no-repeat;background-size:1437px 3331px;background-image:url('", "');background-size:cover;background-position:center;height:100%;width:240px;"], props => props.bgImage);

exports.StyleTopRowPlayer = StyleTopRowPlayer;

const StyleTopRowPlayerImage = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTopRowPlayerImage",
  componentId: "sc-un6rd7-3"
})(["background-position:center;background-repeat:no-repeat;background-size:cover;background-image:url(", ");height:100%;width:240px;"], props => props.BGImage);

exports.StyleTopRowPlayerImage = StyleTopRowPlayerImage;

const StyleTopRowInfo = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTopRowInfo",
  componentId: "sc-un6rd7-4"
})(["align-items:center;background-color:", ";background-image:url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/leaderboard-texture.png');background-position:0 -120px;background-repeat:no-repeat;background-size:1508px 442px;display:flex;flex-direction:column;height:100%;justify-content:center;width:290px;position:relative;", ""], props => props.BGColor + '96' || 'rgba(0, 0, 0, 0.03)', props => props.cssTopRowInfo);

exports.StyleTopRowInfo = StyleTopRowInfo;

const StyleTopRowInfoLogo = _styledComponents.default.img.withConfig({
  displayName: "style__StyleTopRowInfoLogo",
  componentId: "sc-un6rd7-5"
})(["width:100px;z-index:1;"]);

exports.StyleTopRowInfoLogo = StyleTopRowInfoLogo;

const StyleTopRowInfoScore = _styledComponents.default.span.withConfig({
  displayName: "style__StyleTopRowInfoScore",
  componentId: "sc-un6rd7-6"
})(["color:orange;font-size:60px;font-weight:900;z-index:2;", ""], props => props.cssTopRowInfoScore);

exports.StyleTopRowInfoScore = StyleTopRowInfoScore;

const StyleTopRowInfoName = _styledComponents.default.span.withConfig({
  displayName: "style__StyleTopRowInfoName",
  componentId: "sc-un6rd7-7"
})(["color:white;font-size:53px;z-index:2;margin-top:10px;margin-bottom:10px;", ""], props => props.cssTopRowInfoName);

exports.StyleTopRowInfoName = StyleTopRowInfoName;