"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVoteNow = exports.StyleTwitterIcon = exports.StyleTitleMain = exports.StyleTeamName = exports.StyleTeamLogo = exports.StyleTeamContainer = exports.StylePlayersMain = exports.StylePlayersContainer = exports.StyleMainContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMainContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-14539ff-0"
})(["height:1080px;width:1920px;padding-top:80px;text-align:center;position:relative;font-family:futura-pt;", ""], props => props.StyleMainContainer ? props.StyleMainContainer : '');

exports.StyleMainContainer = StyleMainContainer;

const StylePlayersMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayersMain",
  componentId: "sc-14539ff-1"
})(["position:relative;text-align:center;height:710px;", ""], props => props.StylePlayersMain ? props.StylePlayersMain : '');

exports.StylePlayersMain = StylePlayersMain;

const StylePlayersContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayersContainer",
  componentId: "sc-14539ff-2"
})(["display:flex;margin-top:", ";justify-content:center;", ""], props => props.isPlayerOfTheMatch ? '120px' : '160px', props => props.StylePlayersContainer ? props.StylePlayersContainer : '');

exports.StylePlayersContainer = StylePlayersContainer;

const StyleTitleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTitleMain",
  componentId: "sc-14539ff-3"
})(["position:relative;display:flex;", ""], props => props.StyleTitleMain ? props.StyleTitleMain : '');

exports.StyleTitleMain = StyleTitleMain;
const StyleTeamContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleTeamContainer",
  componentId: "sc-14539ff-4"
})(["position:absolute;display:flex;width:100%;justify-content:center;align-items:center;", ""], props => props.StyleTeamContainer ? props.StyleTeamContainer : '');
exports.StyleTeamContainer = StyleTeamContainer;

const StyleTeamLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-14539ff-5"
})(["height:120px;", ""], props => props.StyleTeamLogo ? props.StyleTeamLogo : '');

exports.StyleTeamLogo = StyleTeamLogo;

const StyleTeamName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamName",
  componentId: "sc-14539ff-6"
})(["line-height:90px;font-size:90px;text-transform:uppercase;margin-left:32px;color:", ";font-weight:700;", ""], props => props.isPlayerOfTheMatch ? '#ffffff' : '#141720', props => props.StyleTeamName ? props.StyleTeamName : '');

exports.StyleTeamName = StyleTeamName;
const StyleVoteNow = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleVoteNow",
  componentId: "sc-14539ff-7"
})(["line-height:90px;font-size:90px;font-style:italic;text-transform:uppercase;margin-left:32px;color:#141720;font-weight:800;width:100%;", ""], props => props.StyleVoteNow ? props.StyleVoteNow : '');
exports.StyleVoteNow = StyleVoteNow;

const StyleTwitterIcon = _styledComponents.default.i.withConfig({
  displayName: "styles__StyleTwitterIcon",
  componentId: "sc-14539ff-8"
})(["", ""], props => props.StyleTwitterIcon ? props.StyleTwitterIcon : '');

exports.StyleTwitterIcon = StyleTwitterIcon;