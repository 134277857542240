"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RedTeamPlayerSlider = exports.PlayerImage = exports.MainComponentContainer = exports.InjectedResizableComponentContainer = exports.Container = exports.BlueTeamPlayerSlider = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-1fal563-0"
})(["height:1080px;width:1920px;display:flex;align-content:center;background-size:contain;display:inline-block;"]);

exports.Base = Base;

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1fal563-1"
})(["width:100%;height:100%;display:flex;justify-content:flex-start;flex-direction:column;position:relative;"]);

exports.Container = Container;

const InjectedResizableComponentContainerAnimation = _reactPose.default.div({
  hidden: {
    opacity: 0,
    x: '0px',
    y: '0px',
    height: '95%',
    width: '1820px',
    transition: {
      duration: 800
    }
  },
  normal: {
    opacity: 1,
    x: '0px',
    y: '0px',
    scale: 1,
    width: '1920px',
    height: '95%',
    'font-size': '48px',
    transition: {
      duration: 400
    },
    delay: 200
  },
  resizeBottomBar: {
    opacity: 1,
    scale: 1,
    width: '1250px',
    y: '0px',
    height: '75%',
    'font-size': '38px',
    transition: {
      duration: 400
    }
  },
  resizeSideBar: {
    width: '1920px',
    opacity: 1,
    scale: props => props.scaleSidebar && props.scaleSidebar || .70,
    x: '270px',
    transition: {
      duration: 300
    }
  }
});

const InjectedResizableComponentContainer = (0, _styledComponents.default)(InjectedResizableComponentContainerAnimation).withConfig({
  displayName: "styles__InjectedResizableComponentContainer",
  componentId: "sc-1fal563-2"
})(["display:inline-block;padding:10px;justify-content:center;flex-direction:row;align-self:center;position:absolute;font-size:48px;top:0;font-weight:400;z-index:1;clip-path:polygon(0 0,100% 0%,100% 100%,0% 100%);"]);
exports.InjectedResizableComponentContainer = InjectedResizableComponentContainer;

const MainComponentContainerAnimation = _reactPose.default.div({
  showTable: {
    width: '100%',
    transition: {
      duration: 700
    }
  },
  hideTable: {
    width: '0%',
    transition: {
      duration: 700
    }
  }
});

const MainComponentContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__MainComponentContainer",
  componentId: "sc-1fal563-3"
})(["position:relative;display:flex;justify-content:center;height:1080px;"]);

exports.MainComponentContainer = MainComponentContainer;

const BlueTeamPlayerSliderAnimation = _reactPose.default.div({
  'showBluePlayer': {
    x: '0px',
    transition: {
      duration: 700
    }
  },
  'hideBluePlayer': {
    x: '-490px',
    transition: {
      duration: 700
    }
  }
});

const BlueTeamPlayerSlider = (0, _styledComponents.default)(BlueTeamPlayerSliderAnimation).withConfig({
  displayName: "styles__BlueTeamPlayerSlider",
  componentId: "sc-1fal563-4"
})(["height:85%;width:25%;position:absolute;top:0;left:0;overflow:hidden;"]);
exports.BlueTeamPlayerSlider = BlueTeamPlayerSlider;

const RedTeamPlayerSliderAnimation = _reactPose.default.div({
  'showRedPlayer': {
    x: '0px',
    transition: {
      duration: 700
    }
  },
  'hideRedPlayer': {
    x: '490px',
    transition: {
      duration: 700
    }
  }
});

const RedTeamPlayerSlider = (0, _styledComponents.default)(RedTeamPlayerSliderAnimation).withConfig({
  displayName: "styles__RedTeamPlayerSlider",
  componentId: "sc-1fal563-5"
})(["height:85%;width:25%;position:absolute;top:0;right:0;overflow:hidden;"]);
exports.RedTeamPlayerSlider = RedTeamPlayerSlider;

const PlayerImage = _styledComponents.default.img.withConfig({
  displayName: "styles__PlayerImage",
  componentId: "sc-1fal563-6"
})(["left:", ";right:", ";bottom:0;width:100%;position:absolute;"], props => props.right ? 0 : 'auto', props => props.left ? 0 : 'auto');

exports.PlayerImage = PlayerImage;