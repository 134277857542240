"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleStatValue = exports.StyleStatHeader = exports.StyleStat = exports.StyleMain = exports.StyleFirstPick = exports.StyleContent = exports.StyleChampionIcon = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMain = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-tas3cw-0"
})(["width:375px;height:121px;background-color:#141720;opacity:0.8;position:absolute;top:", "px;left:", "px;overflow:hidden;"], props => props.position.top || 0, props => props.position.left || 0);
exports.StyleMain = StyleMain;
const StyleContent = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleContent",
  componentId: "sc-tas3cw-1"
})(["display:grid;grid-template-columns:60px repeat(3,100px);width:100%;height:100%;margin:auto;justify-content:center;overflow:hidden;"]);
exports.StyleContent = StyleContent;

const StyleChampionIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionIcon",
  componentId: "sc-tas3cw-2"
})(["margin:auto;img{width:50px;height:50px;}"]);

exports.StyleChampionIcon = StyleChampionIcon;

const StyleStat = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStat",
  componentId: "sc-tas3cw-3"
})(["display:grid;grid-template-rows:14px 28px;width:100%;margin:auto;text-align:left;padding-left:9px;color:#F5F5F5;", ""], props => props.showBorderRight && ' border-right: 1px white solid;' || '');

exports.StyleStat = StyleStat;

const StyleFirstPick = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleFirstPick",
  componentId: "sc-tas3cw-4"
})(["display:flex;font-family:aktiv-grotesk-bold;margin:auto;justify-content:center;align-items:center;height:100%;font-weight:700;font-size:60px;padding-top:16px;color:", ";"], props => props.color);

exports.StyleFirstPick = StyleFirstPick;

const StyleStatHeader = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleStatHeader",
  componentId: "sc-tas3cw-5"
})(["font-weight:400;font-size:14px;line-height:21px;font-family:aktiv-grotesk-ex-regular;"]);

exports.StyleStatHeader = StyleStatHeader;

const StyleStatValue = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleStatValue",
  componentId: "sc-tas3cw-6"
})(["font-weight:700;font-size:25px;line-height:37px;font-family:aktiv-grotesk-bold;"]);

exports.StyleStatValue = StyleStatValue;