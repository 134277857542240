"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VoteNowTextContainer = exports.VoteNowText = exports.VoteNowContainer = exports.UpperLineContainer = exports.TeamContainer = exports.PlayersContainer = exports.PlayerName = exports.PlayerImageContainer = exports.PlayerImage = exports.PlayerHashtagContainer = exports.PlayerContainerBorderline = exports.PlayerBackground = exports.HashtagIcon = exports.HashtagContainer = exports.Container = exports.BottomLineContainer = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-1dfbhf5-0"
})(["height:100%;width:100%;max-height:940px;"]);

exports.Base = Base;

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1dfbhf5-1"
})(["height:100%;width:100%;font-weight:700;font-size:50px;display:flex;flex-direction:column;"]);

exports.Container = Container;

const hasthtagContainerAnimation = _reactPose.default.div({
  hide: {
    y: 200,
    transition: {
      duration: 400
    },
    delayChildren: 1000
  },
  display: {
    y: 0,
    transition: {
      duration: 400
    },
    delayChildren: 1000
  }
});

const HashtagContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__HashtagContainer",
  componentId: "sc-1dfbhf5-2"
})(["height:600px;width:100%;display:flex;flex-direction:row;overflow:hidden;color:white;"]);

exports.HashtagContainer = HashtagContainer;
const PlayerHashtagContainer = (0, _styledComponents.default)(hasthtagContainerAnimation).withConfig({
  displayName: "styles__PlayerHashtagContainer",
  componentId: "sc-1dfbhf5-3"
})(["display:flex;justify-content:center;align-items:center;flex-direction:row;width:20%;overflow:hidden;position:relative;font-size:45px;"]);
exports.PlayerHashtagContainer = PlayerHashtagContainer;

const HashtagIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__HashtagIcon",
  componentId: "sc-1dfbhf5-4"
})(["text-shadow:-2px 0px 1px ", ",2px 0px 1px ", ";"], props => props.colorMain, props => props.colorSub);

exports.HashtagIcon = HashtagIcon;

const PlayerNameAnimation = _reactPose.default.span({
  hide: {
    width: '0px',
    transition: {
      duration: 400
    }
  },
  display: {
    width: '100%',
    transition: {
      duration: 400
    }
  }
});

const PlayerName = (0, _styledComponents.default)(PlayerNameAnimation).withConfig({
  displayName: "styles__PlayerName",
  componentId: "sc-1dfbhf5-5"
})(["width:0px;overflow:hidden;letter-spacing:4px;text-shadow:-2px 0px 1px ", ",2px 0px 1px ", ";"], props => props.colorMain, props => props.colorSub);
exports.PlayerName = PlayerName;

const PlayersContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__PlayersContainer",
  componentId: "sc-1dfbhf5-6"
})(["width:100%;height:650px;position:relative;"]);

exports.PlayersContainer = PlayersContainer;

const UpperLineContainerAnimation = _reactPose.default.div({
  hide: {
    width: '0px',
    transition: {
      duration: 400
    }
  },
  display: {
    width: '100%',
    transition: {
      duration: 400
    }
  }
});

const UpperLineContainer = (0, _styledComponents.default)(UpperLineContainerAnimation).withConfig({
  displayName: "styles__UpperLineContainer",
  componentId: "sc-1dfbhf5-7"
})(["width:100%;height:100%;border-top:5px solid #795b18;z-index:2;position:absolute;top:0;"]);
exports.UpperLineContainer = UpperLineContainer;

const BottomLineContainerAnimation = _reactPose.default.div({
  hide: {
    x: '1920px',
    transition: {
      duration: 400
    }
  },
  display: {
    x: '0px',
    transition: {
      duration: 400
    }
  }
});

const BottomLineContainer = (0, _styledComponents.default)(BottomLineContainerAnimation).withConfig({
  displayName: "styles__BottomLineContainer",
  componentId: "sc-1dfbhf5-8"
})(["width:100%;height:650px;border-bottom:5px solid #795b18;z-index:2;position:absolute;bottom:0;"]);
exports.BottomLineContainer = BottomLineContainer;

const TeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamContainer",
  componentId: "sc-1dfbhf5-9"
})(["width:100%;height:625px;display:flex;flex-direction:row;"]);

exports.TeamContainer = TeamContainer;

const PlayerImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__PlayerImageContainer",
  componentId: "sc-1dfbhf5-10"
})(["position:relative;display:flex;justify-content:center;align-items:center;flex-direction:row;width:20%;overflow:hidden;"]);

exports.PlayerImageContainer = PlayerImageContainer;

const PlayerAnimation = _reactPose.default.div({
  hide: {
    width: '0px',
    transition: {
      duration: 400
    }
  },
  display: {
    width: '100%',
    transition: {
      duration: 400
    }
  }
});

const PlayerBackground = (0, _styledComponents.default)(PlayerAnimation).withConfig({
  displayName: "styles__PlayerBackground",
  componentId: "sc-1dfbhf5-11"
})(["background-image:", ";background-color:", ";background-blend-mode:multiply;background-size:cover;background-position-x:", ";height:100%;position:absolute;left:0;"], props => props.backgroundImage ? "url(".concat(props.backgroundImage, ")") : 'none', props => props.teamMainColor ? props.teamMainColor : 'none', props => props.xPosition ? "".concat(props.xPosition, "px") : 'none');
exports.PlayerBackground = PlayerBackground;
const PlayerImage = (0, _styledComponents.default)(PlayerAnimation).withConfig({
  displayName: "styles__PlayerImage",
  componentId: "sc-1dfbhf5-12"
})(["background-image:", ";background-repeat:no-repeat;background-position-x:-155px;height:222%;width:100%;position:absolute;left:0;z-index:0;overflow:hidden;background-size:auto;transform:translateX(0px);"], props => props.src ? "url(".concat(props.src, ")") : 'none');
exports.PlayerImage = PlayerImage;

const PlayerContainerBorderlineAnimation = _reactPose.default.div({
  hide: {
    y: _ref => {
      let {
        direction
      } = _ref;
      return direction === 'down' ? '980px' : '-650px';
    },
    transition: {
      duration: 650
    }
  },
  display: {
    y: _ref2 => {
      let {
        direction
      } = _ref2;
      return direction === 'down' ? '0px' : '0px';
    },
    transition: {
      duration: 650
    }
  }
});

const PlayerContainerBorderline = (0, _styledComponents.default)(PlayerContainerBorderlineAnimation).withConfig({
  displayName: "styles__PlayerContainerBorderline",
  componentId: "sc-1dfbhf5-13"
})(["border-right:5px solid #795b18;width:100%;height:100%;position:absolute;top:0;"]);
exports.PlayerContainerBorderline = PlayerContainerBorderline;

const VoteNowContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__VoteNowContainer",
  componentId: "sc-1dfbhf5-14"
})(["width:100%;height:100%;overflow:hidden;display:flex;justify-content:center;align-items:center;"]);

exports.VoteNowContainer = VoteNowContainer;

const VoteNowTextContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__VoteNowTextContainer",
  componentId: "sc-1dfbhf5-15"
})(["height:95px;display:flex;justify-content:center;align-items:center;overflow:hidden;"]);

exports.VoteNowTextContainer = VoteNowTextContainer;

const voteNowTextAnimation = _reactPose.default.span({
  hide: {
    y: 200,
    transition: {
      duration: 650
    }
  },
  display: {
    y: 0,
    transition: {
      duration: 650
    }
  }
});

const VoteNowText = (0, _styledComponents.default)(voteNowTextAnimation).withConfig({
  displayName: "styles__VoteNowText",
  componentId: "sc-1dfbhf5-16"
})(["color:#795b18;font-size:70px;span{margin-left:20px;font-weight:700;}", ""], props => props.css);
exports.VoteNowText = VoteNowText;