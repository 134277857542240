"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.StyleUnderline = exports.StyleTitleText = exports.StyleSubtitle = exports.HeaderTitle = exports.Header = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const HeaderTitleAnimation = _reactPose.default.div({
  HeaderTitleVisible: {
    opacity: 1,
    y: '0%',
    transition: {
      duration: 700
    }
  },
  HeaderTitleHidden: {
    opacity: 1,
    y: '-120%',
    transition: {
      duration: 700
    }
  }
});

const Header = _styledComponents.default.div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-1u13zo1-0"
})(["font-size:84pt;text-align:center;overflow:hidden;display:flex;padding-top:0px;color:white;justify-content:center;align-items:center;overflow:hidden;font-family:\"Refrigerator\";", ""], props => props.Header ? props.Header : '');

exports.Header = Header;
const HeaderTitle = (0, _styledComponents.default)(HeaderTitleAnimation).withConfig({
  displayName: "styles__HeaderTitle",
  componentId: "sc-1u13zo1-1"
})(["letter-spacing:4px;display:flex;flex-direction:row;width:100%;justify-content:center;"]);
exports.HeaderTitle = HeaderTitle;

const StyleTitleText = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTitleText",
  componentId: "sc-1u13zo1-2"
})(["", ""], props => props.cssTitleText ? props.cssTitleText : '');

exports.StyleTitleText = StyleTitleText;

const Title = _styledComponents.default.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1u13zo1-3"
})(["display:inline-block;font-size:140px;letter-spacing:4px;text-shadow:-7px 0px 1px ", ",7px 0px 1px ", ";letter-spacing:6px;flex:4;font-weight:800;color:", ";line-height:160px;font-family:\"Refrigerator\";", ""], props => props.textShadowLeft || '#ffffff00', props => props.textShadowRight || '#ffffff00', props => props.textColor, props => props.cssTitle ? props.cssTitle : '');

exports.Title = Title;

const StyleUnderline = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleUnderline",
  componentId: "sc-1u13zo1-4"
})(["width:100%;height:8px;position:absolute;bottom:3px;background:", ";left:0;"], props => props.underlineColor && props.underlineColor);

exports.StyleUnderline = StyleUnderline;

const HeaderSubtitleAnimation = _reactPose.default.div({
  HeaderTitleVisible: {
    opacity: 1,
    y: '10%',
    transition: {
      duration: 700
    }
  },
  HeaderTitleHidden: {
    opacity: 0,
    y: '-200px',
    transition: {
      duration: 700
    }
  }
});

const StyleSubtitle = (0, _styledComponents.default)(HeaderSubtitleAnimation).withConfig({
  displayName: "styles__StyleSubtitle",
  componentId: "sc-1u13zo1-5"
})(["position:absolute;top:160px;width:100%;display:flex;justify-content:center;margin:auto;font-size:40px;", ""], props => props.cssSubtitle ? props.cssSubtitle : '');
exports.StyleSubtitle = StyleSubtitle;