"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Line = exports.Char = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const BaseAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
});

const Base = (0, _styledComponents.default)(BaseAnimation).withConfig({
  displayName: "styles__Base",
  componentId: "sc-1u46ar-0"
})(["text-align:center;font-size:64pt;display:flex;flex-direction:column;transform:", ";z-index:200;color:whitesmoke;"], props => props.linesNumber === 3 ? 'translateY(-60px)' : props.linesNumber === 2 ? 'translateY(66px)' : 'translateY(43px)');
exports.Base = Base;

const LineAnimation = _reactPose.default.div({
  moveUp: {
    y: _ref => {
      let {
        index
      } = _ref;
      return index === 0 ? '150px' : "".concat(index * 150, "px");
    },
    transition: {
      duration: 300
    }
  },
  display: {
    y: '0px',
    transition: {
      duration: 300
    }
  }
});

const Line = (0, _styledComponents.default)(LineAnimation).withConfig({
  displayName: "styles__Line",
  componentId: "sc-1u46ar-1"
})(["display:inline-block;letter-spacing:18px;font-size:116pt;height:125px;text-shadow:-6px 0px 1px ", ",6px 0px 1px ", ";"], props => props.colorMain, props => props.colorSub);
exports.Line = Line;

const CharAnimation = _reactPose.default.span({
  hidden: {
    opacity: 0,
    transition: {
      duration: 200
    }
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 200
    }
  }
});

const Char = (0, _styledComponents.default)(CharAnimation).withConfig({
  displayName: "styles__Char",
  componentId: "sc-1u46ar-2"
})([""]);
exports.Char = Char;