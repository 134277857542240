"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamLogoContainer = exports.StatsNameContainer = exports.StatsContainer = exports.Stats = exports.Stat = exports.RightContainer = exports.PositionLogo = exports.PlayerName = exports.PlayerInfo = exports.Player = exports.LeftContainer = exports.Container = exports.ChampionsRightContainer = exports.ChampionsLeftContainer = exports.ChampionsContainer = exports.ChampionName = exports.ChampionImageContainer = exports.ChampionImage = exports.Champion = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-11dvg36-0"
})(["position:absolute;bottom:0;width:1920px;left:0;height:250px;font-family:inherit;color:white;font-weight:700;"]);

exports.Base = Base;

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-11dvg36-1"
})(["position:relative;width:100%;height:100%;overflow:hidden;"]);

exports.Container = Container;

const PlayerAnimation = _reactPose.default.div({
  hide: {
    x: _ref => {
      let {
        position
      } = _ref;
      return position === 'left' ? '-960px' : '960px';
    },
    y: '-83px',
    transition: {
      duration: 400
    }
  },
  display: {
    x: '0px',
    y: '-83px',
    transition: {
      duration: 400
    }
  },
  displayInfo: {
    y: '-83px'
  }
});

const Player = (0, _styledComponents.default)(PlayerAnimation).withConfig({
  displayName: "styles__Player",
  componentId: "sc-11dvg36-2"
})(["position:absolute;transform:", ";left:", ";right:", ";background-color:#030d23;height:83px;width:960px;border-bottom:", ";display:flex;justify-content:center;overflow:hidden;"], props => props.position !== 'left' ? 'translate(960px, -150px)' : 'translate(800px, -150px)', props => props.position === 'left' ? 0 : 'unset', props => props.position === 'right' ? 0 : 'unset', props => "solid 8px ".concat(props.color));
exports.Player = Player;

const PlayerInfoAnimation = _reactPose.default.div({
  hide: {
    y: '80px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '80px',
    transition: {
      duration: 400
    }
  },
  displayInfo: {
    y: '0px',
    transition: {
      duration: 400
    }
  }
});

const PlayerInfo = (0, _styledComponents.default)(PlayerInfoAnimation).withConfig({
  displayName: "styles__PlayerInfo",
  componentId: "sc-11dvg36-3"
})(["display:flex;flex-direction:row;justify-content:center;align-items:center;transform:translateY(80px);"]);
exports.PlayerInfo = PlayerInfo;

const PositionLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__PositionLogo",
  componentId: "sc-11dvg36-4"
})(["background-image:", ";background-repeat:no-repeat;background-size:60px;height:60px;width:60px;filter:invert(.5) sepia(1) saturate(5) hue-rotate(10deg);"], props => "url(".concat(props.logo, ")"));

exports.PositionLogo = PositionLogo;

const PlayerName = _styledComponents.default.span.withConfig({
  displayName: "styles__PlayerName",
  componentId: "sc-11dvg36-5"
})(["text-transform:uppercase;font-size:50px;letter-spacing:2px;margin-left:20px;margin-right:20px;"]);

exports.PlayerName = PlayerName;

const StatsContainerAnimation = _reactPose.default.div({
  hide: {
    y: '250px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '0px',
    transition: {
      duration: 400
    }
  },
  push: {
    y: '-250px',
    transition: {
      duration: 400
    }
  }
});

const StatsContainer = (0, _styledComponents.default)(StatsContainerAnimation).withConfig({
  displayName: "styles__StatsContainer",
  componentId: "sc-11dvg36-6"
})(["width:100%;height:100%;display:flex;flex-direction:row;justify-content:flex-start;overflow:hidden;transform:translateY(250px);background:#030d23;"]);
exports.StatsContainer = StatsContainer;

const LeftContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__LeftContainer",
  componentId: "sc-11dvg36-7"
})(["position:relative;display:flex;flex:4;height:100%;width:100%;background-image:", ";"], props => "linear-gradient(to right, ".concat(props.mainColor.concat('99'), ", rgba(0, 0, 0, 0))"));

exports.LeftContainer = LeftContainer;

const StatsNameContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StatsNameContainer",
  componentId: "sc-11dvg36-8"
})(["display:flex;flex:1;background-color:#43434399;color:#795b18;display:flex;flex-direction:column;justify-content:space-evenly;height:100%;text-align:center;"]);

exports.StatsNameContainer = StatsNameContainer;

const RightContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__RightContainer",
  componentId: "sc-11dvg36-9"
})(["position:relative;display:flex;flex:4;height:100%;width:100%;background-image:", ";"], props => "linear-gradient(to left, ".concat(props.mainColor.concat('99'), ", rgba(0, 0, 0, 0))"));

exports.RightContainer = RightContainer;

const TeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamLogoContainer",
  componentId: "sc-11dvg36-10"
})(["position:absolute;right:", ";left:", ";z-index:2;overflow:hidden;width:100%;img{opacity:0.8;transform:", ";}"], props => props.position === 'left' ? 'unset' : '0', props => props.position === 'left' ? '0' : 'unset', props => props.position === 'left' ? 'translate(-140px,-300px)' : 'translate(140px,-300px)');

exports.TeamLogoContainer = TeamLogoContainer;

const Stats = _styledComponents.default.div.withConfig({
  displayName: "styles__Stats",
  componentId: "sc-11dvg36-11"
})(["position:absolute;right:", ";left:", ";display:flex;flex-direction:column;justify-content:space-evenly;height:100%;text-align:center;width:200px;letter-spacing:3px;"], props => props.position === 'right' ? 'unset' : 0, props => props.position === 'left' ? 'unset' : 0);

exports.Stats = Stats;

const Stat = _styledComponents.default.div.withConfig({
  displayName: "styles__Stat",
  componentId: "sc-11dvg36-12"
})(["font-size:50px;"]);

exports.Stat = Stat;

const ChampionsContainerAnimation = _reactPose.default.div({
  hide: {
    y: '0px',
    transition: {
      duration: 400
    }
  },
  display: {
    y: '-250px',
    transition: {
      duration: 400
    }
  }
});

const ChampionsContainer = (0, _styledComponents.default)(ChampionsContainerAnimation).withConfig({
  displayName: "styles__ChampionsContainer",
  componentId: "sc-11dvg36-13"
})(["width:100%;height:100%;overflow:hidden;display:flex;flex-direction:row;justify-content:center;"]);
exports.ChampionsContainer = ChampionsContainer;

const ChampionsLeftContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionsLeftContainer",
  componentId: "sc-11dvg36-14"
})(["position:relative;display:flex;flex-direction:row;flex:1;height:100%;width:960px;"]);

exports.ChampionsLeftContainer = ChampionsLeftContainer;

const ChampionsRightContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionsRightContainer",
  componentId: "sc-11dvg36-15"
})(["position:relative;display:flex;flex-direction:row;flex:1;height:100%;width:960px;"]);

exports.ChampionsRightContainer = ChampionsRightContainer;

const Champion = _styledComponents.default.div.withConfig({
  displayName: "styles__Champion",
  componentId: "sc-11dvg36-16"
})(["display:flex;flex-direction:column;width:100%;max-width:", ";"], props => props.championsLenght === 1 ? '100%' : props.championsLenght === 2 ? '480px' : '320px');

exports.Champion = Champion;

const ChampionName = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionName",
  componentId: "sc-11dvg36-17"
})(["flex:2;font-size:30px;text-align:center;letter-spacing:1px;background-color:", ";line-height:1.7;"], props => props.side === 'left' ? "".concat(props.mainColor).concat(props.index === 0 ? 'B3' : props.index === 1 ? 'D9' : '') : "".concat(props.mainColor).concat(props.index === 0 ? '' : props.index === 1 ? 'D9' : 'B3'));

exports.ChampionName = ChampionName;

const ChampionImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChampionImageContainer",
  componentId: "sc-11dvg36-18"
})(["flex:8;height:100%;overflow:hidden;"]);

exports.ChampionImageContainer = ChampionImageContainer;

const ChampionImage = _styledComponents.default.img.withConfig({
  displayName: "styles__ChampionImage",
  componentId: "sc-11dvg36-19"
})(["transform:", ";width:", ";"], props => props.championsLenght === 1 ? 'translate(0px,-165px)' : props.championsLenght === 2 ? 'translate(0px,-65px)' : 'translate(-50px, -50px)', props => props.championsLenght === 1 ? '100%' : props.championsLenght === 2 ? '100%' : 'auto');

exports.ChampionImage = ChampionImage;