"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTipContainer = exports.StyleMain = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTools = require("styled-tools");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-1v8o1xx-0"
})(["position:absolute;top:0;left:0;height:1080px;width:1920px;z-index:-1;"]);

exports.StyleMain = StyleMain;

const StyleTipContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTipContainer",
  componentId: "sc-1v8o1xx-1"
})(["position:absolute;width:284px;height:69px;transition:transform 300ms linear;z-index:100;", " ", " p{font-family:futura-pt,sans-serif;font-style:normal;font-weight:700;font-size:18px;color:#ffffff;position:absolute;", " height:70px;width:220px;padding:10px;display:flex;justify-content:center;align-items:center;flex-wrap:wrap;text-align:center;white-space:pre-line;}img{width:100%;height:100%;}", ";"], (0, _styledTools.switchProp)("teamId", {
  "team100": (0, _styledComponents.css)(["left:0px;transform:translateX(-100%);"]),
  "team200": (0, _styledComponents.css)(["right:0px;transform:translateX(100%);"])
}), (0, _styledTools.switchProp)("position", {
  "top": (0, _styledComponents.css)(["top:140px;"]),
  "jungle": (0, _styledComponents.css)(["top:235px;"]),
  "mid": (0, _styledComponents.css)(["top:332px;"]),
  "adc": (0, _styledComponents.css)(["top:429px;"]),
  "support": (0, _styledComponents.css)(["top:523px;"])
}), props => props.teamId === 'team100' ? "left: 65px;" : "right: 65px;", props => props.show && "transform: translateX(0);" || '');

exports.StyleTipContainer = StyleTipContainer;