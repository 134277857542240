"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StylePredictionsContainer = exports.StyleMainContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMainContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-r4em7z-0"
})(["position:absolute;top:0;left:0;height:1080px;width:1920px;padding-top:230px;display:flex;"]);

exports.StyleMainContainer = StyleMainContainer;

const StylePredictionsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePredictionsContainer",
  componentId: "sc-r4em7z-1"
})(["top:250px;margin:auto;display:grid;width:1500px;height:700px;grid-template-columns:", ";justify-content:center;grid-gap:20px;"], props => "repeat( ".concat(props.itemsPerRow || 4, ", 1fr)"));

exports.StylePredictionsContainer = StylePredictionsContainer;