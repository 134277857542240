"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideo = exports.StyleText = exports.StyleContainerTimer = exports.StyleContainerText = exports.StyleContainerLogo = exports.StyleBase = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const ContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    opacity: 0,
    transition: {
      duration: 600
    }
  },
  show: {
    opacity: 1,
    transition: {
      duration: 600
    }
  }
});

const StyleBase = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-1vl0v3h-0"
})(["width:1920px;height:1080px;display:flex;flex-direction:row;position:absolute;left:0;top:0;"]);
exports.StyleBase = StyleBase;

const StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-1vl0v3h-1"
})(["height:100%;width:100%;background-color:transparent;position:absolute;z-index:1;"]);

exports.StyleVideo = StyleVideo;

const StyleContainerLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContainerLogo",
  componentId: "sc-1vl0v3h-2"
})(["height:100%;width:50%;background-color:transparent;position:absolute;display:flex;align-items:center;justify-content:center;z-index:2;img{width:500px;height:auto;}", ""], props => props.css);

exports.StyleContainerLogo = StyleContainerLogo;

const StyleContainerText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContainerText",
  componentId: "sc-1vl0v3h-3"
})(["height:100%;width:50%;left:50%;background-color:transparent;position:absolute;display:flex;flex-flow:column;align-items:center;justify-content:center;text-align:center;z-index:2;", ""], props => props.css);

exports.StyleContainerText = StyleContainerText;

const StyleText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleText",
  componentId: "sc-1vl0v3h-4"
})(["z-index:2;display:inline-block;", ""], props => props.css);

exports.StyleText = StyleText;

const StyleContainerTimer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContainerTimer",
  componentId: "sc-1vl0v3h-5"
})(["font-size:70px;width:100%;flex:0 0 80px;color:#ffffff;z-index:2;", ""], props => props.css);

exports.StyleContainerTimer = StyleContainerTimer;