"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamName = exports.StyledVSTime = exports.StyledLogoImg = exports.StyleVersusDetailContainer = exports.StyleVS = exports.StyleTriCodeName = exports.StyleTime = exports.StyleTeamDetailContainer = exports.StyleScoreSpan = exports.StyleScoreDetailContainer = exports.StyleScoreDetail = exports.StyleScoreDash = exports.StyleRowDetailContainer = exports.StyleRowContainer = exports.StyleName = exports.StyleLogoWrapper = exports.StyleLogoContainer = exports.StyleLittleScore = exports.StyleGradientBar = exports.StyleGameDetail = exports.StyleDay = exports.StyleDate = exports.StyleContainer = exports.StyleBorders = exports.StyleBase = exports.SpanVS = exports.H1VS = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

var _styledTools = require("styled-tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const StyleLogoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLogoWrapper",
  componentId: "sc-6n2kuj-0"
})(["display:flex;align-items:center;height:100%;width:30%;overflow:hidden;margin-right:-2px;z-index:2;border-right:", ";border-left:", ";border-right:", ";border-left:", ";", " ", ";", ";", ""], props => props.borderRight && "5px solid ".concat(props.borderRight), props => props.borderLeft && "5px solid ".concat(props.borderLeft), props => props.loserLeft && props.borderRight && "5px solid ".concat(props.borderRight, "4D"), props => props.loserRight && props.borderLeft && "5px solid ".concat(props.borderLeft, "4D"), props => props.cssLogo, props => props.loserLeft && props.cssLogoLoserLeft ? props.cssLogoLoserLeft : '', props => props.loserRight && props.cssLogoLoserRight ? props.cssLogoLoserRight : '', props => props.cssAdjust);

exports.StyleLogoWrapper = StyleLogoWrapper;

const StyledLogoImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledLogoImg",
  componentId: "sc-6n2kuj-1"
})(["width:100%;height:100%;", ""], props => props.StyledLogoImg || '');

exports.StyledLogoImg = StyledLogoImg;

const StyleTriCodeName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTriCodeName",
  componentId: "sc-6n2kuj-2"
})(["", " ", ""], props => props.StyleTriCodeName || '', props => props.cssAdjust);

exports.StyleTriCodeName = StyleTriCodeName;

const StyleLittleScore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLittleScore",
  componentId: "sc-6n2kuj-3"
})(["", " ", ""], props => props.StyleLittleScore || '', props => props.cssAdjust);

exports.StyleLittleScore = StyleLittleScore;

const StyleVS = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleVS",
  componentId: "sc-6n2kuj-4"
})(["", ""], props => props.StyleVS || '');

exports.StyleVS = StyleVS;

const StyledVSTime = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVSTime",
  componentId: "sc-6n2kuj-5"
})(["", " ", " ", ""], props => props.StyledVSTime || '', props => props.cssVersusNext || '', props => props.cssAdjust);

exports.StyledVSTime = StyledVSTime;

const TeamName = _styledComponents.default.p.withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-6n2kuj-6"
})(["font-family:dharma-gothic-e-bold-italic;", ";", ";", ";"], props => props.oneMatch ? "\n    font-size: 64px;\n    letter-spacing: 0.64px;\n    color: #FFFFF;\n    margin-top: 60px;\n  " : "\n    font-size: 120px;\n    color: #F5F5F5;\n    margin-top: 16px;\n  ", props => props.cssVS || '', props => props.cssVSBo5 || '');

exports.TeamName = TeamName;

const SpanVS = _styledComponents.default.span.withConfig({
  displayName: "styles__SpanVS",
  componentId: "sc-6n2kuj-7"
})(["font-family:aktiv-grotesk-medium;font-weight:500;color:#ffffff;", " ", ""], props => props.oneMatch ? "\n        font-size: 41px;\n        letter-spacing: 1.23px;\n    " : "\n        font-size: 30px;\n        margin-top: 10px;\n    ", props => props.cssVS || '');

exports.SpanVS = SpanVS;

const H1VS = _styledComponents.default.h1.withConfig({
  displayName: "styles__H1VS",
  componentId: "sc-6n2kuj-8"
})(["", " font-family:dharma-gothic-e-bold,sans-serif;font-weight:700;color:#F5F5F5;", ""], props => props.oneMatch ? "\n    font-size: 180px;\n    line-height: 150px;\n    padding: 34px 0 25px 0;\n  " : "\n    font-size: 120px;\n    line-height: 100px;\n    padding: 25px 0 6px 0;\n  ", props => props.cssVS || '');

exports.H1VS = H1VS;

const StyleBase = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-6n2kuj-9"
})(["position:relative;color:white;font-weight:100;align-items:center;font-family:inherit;font-size:40px;letter-spacing:2px;", " ", ""], props => !props.isDetailed ? "\n        overflow: hidden;\n        margin-top: 20px;\n        width: 100%;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        height: 860px;\n    " : "\n        width: 1540px;\n        margin-top: 82px;\n        margin-left: auto;\n        margin-right: auto;\n    ", props => props.cssVS || '');

exports.StyleBase = StyleBase;

const ContainerAnimation = _reactPose.default.div({
  show: {
    y: _ref => {
      let {
        resize
      } = _ref;
      return resize ? -75 : 0;
    },
    scale: _ref2 => {
      let {
        resize
      } = _ref2;
      return resize ? 0.8 : 1;
    },
    transition: _ref3 => {
      let {
        speedPanel
      } = _ref3;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: _ref4 => {
      let {
        speedPanel
      } = _ref4;
      return speedPanel;
    },
    staggerDirection: 1
  },
  hide: {
    y: _ref5 => {
      let {
        resize
      } = _ref5;
      return resize ? -75 : 0;
    },
    scale: _ref6 => {
      let {
        resize
      } = _ref6;
      return resize ? 0.8 : 1;
    },
    transition: _ref7 => {
      let {
        speedPanel
      } = _ref7;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: _ref8 => {
      let {
        speedPanel
      } = _ref8;
      return speedPanel;
    },
    staggerDirection: -1
  }
});

const StyleContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleContainer",
  componentId: "sc-6n2kuj-10"
})(["position:relative;", " ", ""], props => !props.isDetailed ? "\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        width: 80%;\n        height: 100%;\n    " : '', props => props.cssVS || '');
exports.StyleContainer = StyleContainer;

const RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    y: -200,
    z: 1,
    scale: 1,
    opacity: 0,
    transition: {
      duration: 400
    },
    applyAtStart: {
      scale: 1
    }
  },
  show: {
    y: 0,
    z: 1,
    // scale: ({nextMatch}) => nextMatch ? 1.03 : 1,
    opacity: 1,
    transition: {
      duration: 400
    },
    delay: 100,
    delayChildren: 400
  }
});

const StyleRowContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowContainer",
  componentId: "sc-6n2kuj-11"
})(["position:relative;display:flex;flex-direction:row;justify-content:center;align-items:flex-start;margin-bottom:10px;z-index:", ";width:100%;flex:1 1 110px;max-height:160px;height:0%;background:", ";text-transform:uppercase;overflow:'visible';overflow:", ";", ";", ";", " ", " ", ";", ";", ";", ";", ";"], props => props.nextMatch ? 30 : 2, props => props.nextMatch ? 'linear-gradient(0deg, #540035 0%, #f00020 100%)' : '', props => props.nextMatch ? 'visible' : 'hidden', props => props.team100Color ? "border-left: 10px solid ".concat(props.team100Color, ";") : '', props => props.team200Color ? "border-right: 10px solid ".concat(props.team200Color, ";") : '', props => props.cssRow, props => props.cssAdjust, props => props.changeWinnerBorder && props.cssWinner === 'right' && props.changeWinnerBorderColor ? props.changeWinnerBorderColor : '', props => props.changeWinnerBorder && props.cssWinner === 'left' && props.changeWinnerBorderColor ? props.changeWinnerBorderColor : '', props => props.changeWinnerBorder && props.cssWinner === 'right' && props.changeWinnerBorderColor ? "border-width: 0px 10px 0px 0px;" : '', props => props.changeWinnerBorder && props.cssWinner === 'left' && props.changeWinnerBorderColor ? "border-width: 0px 0px 0px 10px;" : '', (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;background-color:lightgray;"])));
exports.StyleRowContainer = StyleRowContainer;

const StyleDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDate",
  componentId: "sc-6n2kuj-12"
})(["position:relative;flex:1 1 100px;align-items:center;justify-content:center;display:flex;flex-direction:column;height:100%;text-transform:uppercase;font-size:25px;line-height:35px;font-size:35px;z-index:4;padding-left:", ";", " ", " ", ";"], props => props.nextMatch ? '20px' : '0px', props => props.cssDate, props => props.cssAdjust, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;"])));

exports.StyleDate = StyleDate;

const StyleDay = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleDay",
  componentId: "sc-6n2kuj-13"
})(["z-index:4;"]);

exports.StyleDay = StyleDay;

const StyleTime = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTime",
  componentId: "sc-6n2kuj-14"
})(["z-index:4;", ""], props => props.StyleTime || '');

exports.StyleTime = StyleTime;

const StyleName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleName",
  componentId: "sc-6n2kuj-15"
})(["flex:5;display:flex;justify-content:", ";text-align:", ";align-items:center;padding-left:", ";padding-right:", ";height:100%;", ";", ";", ";", ";", ";"], props => props.position === 'left' ? 'flex-start' : 'flex-end', props => props.position === 'left' ? 'left' : 'right', props => props.position === 'left' ? '20px' : 'none', props => props.position === 'right' ? '20px' : 'none', props => props.cssName, props => props.cssAdjust, props => props.position === 'left' && props.StyleNameLeftAdjust ? props.StyleNameLeftAdjust : '', props => props.position === 'right' && props.StyleNameRightAdjust ? props.StyleNameRightAdjust : '', (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;"])));

exports.StyleName = StyleName;

const StyleLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLogoContainer",
  componentId: "sc-6n2kuj-16"
})(["display:flex;align-items:center;height:100%;width:12.3%;overflow:hidden;margin-right:-2px;z-index:2;padding-right:", ";border-right:", ";border-left:", ";", " ", " ", ";img{opacity:", ";padding:'0px';max-width:100%;max-height:80%;margin:auto;}"], props => props.nextMatch && props.last ? '20px' : '0px', props => props.borderRight && "8px solid ".concat(props.borderRight), props => props.borderLeft && "8px solid ".concat(props.borderLeft), props => props.cssLogo, props => props.cssAdjust, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.5;"])), props => props.isLoser ? '0.3' : '1');

exports.StyleLogoContainer = StyleLogoContainer;

const BordersAnimation = _reactPose.default.div({
  initialPose: 'hide',
  showNext: {
    width: '100%',
    x: '-50%',
    opacity: 1,
    transition: {
      duration: 400
    }
  },
  hide: {
    width: '0%',
    x: '-50%',
    opacity: 0,
    transition: {
      duration: 200
    }
  }
});

const StyleBorders = (0, _styledComponents.default)(BordersAnimation).withConfig({
  displayName: "styles__StyleBorders",
  componentId: "sc-6n2kuj-17"
})(["position:absolute;left:50%;top:0;height:100%;border:10px solid ", ";z-index:100;", ""], props => props.trimColor || '#ffffff', props => props.cssBorders);
exports.StyleBorders = StyleBorders;
const StyleRowDetailContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowDetailContainer",
  componentId: "sc-6n2kuj-18"
})(["top:0;position:relative;width:100%;height:", ";align-items:flex-start;margin-bottom:0px;z-index:", ";text-transform:uppercase;overflow:visible;background-color:#000220;", ";", " &::after{height:10px;width:100%;background:linear-gradient(to right,#E10600,#730041);display:block;content:\"\";grid-column-start:1;grid-column-end:15;position:absolute;bottom:-10px;}", ""], props => props.nextMatch ? '105%' : '100%', props => props.nextMatch ? 30 : 2, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;background-color:lightgray;"])), props => props.oneMatch ? "\n        height: 670px;\n        display: flex;\n    " : "\n        display: grid;\n        grid-template-columns: repeat(14, 1fr);\n        height: 334px;\n        &:first-of-type {\n            border-bottom: 2px solid #F5F5F5;\n        }\n        &:first-of-type::after {\n            content: none;\n        }\n    ", props => props.cssVS || '');
exports.StyleRowDetailContainer = StyleRowDetailContainer;
const StyleGradientBar = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleGradientBar",
  componentId: "sc-6n2kuj-19"
})(["position:absolute;bottom:-10px;left:0;right:0;height:10px;z-index:2;background:linear-gradient(to left,#15ace2,#8de8f9);", ""], props => props.cssVS || '');
exports.StyleGradientBar = StyleGradientBar;

const StyleTeamDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamDetailContainer",
  componentId: "sc-6n2kuj-20"
})(["", " justify-items:center;justify-content:center;align-items:center;align-content:center;margin:auto;width:100%;p{text-align:center;}", " opacity:", ";", ";", ";", ";"], props => props.oneMatch ? "\n        display: flex;\n        flex-direction: column;\n        p {\n            order: ".concat(props.first ? 1 : 0, ";\n        }\n        img {\n            min-width: 370px;\n            width: 370px;\n            height: 370px;\n            min-height: 370px;\n            max-height: 100%;\n            object-fit: contain;\n        }\n    ") : "\n        display: grid;\n        // grid-template-rows: 4fr 1fr;\n        grid-template-columns: repeat(6,1fr);\n        grid-column-start: ".concat(props.first ? 1 : 9, ";\n        grid-column-end: ").concat(props.first ? 7 : 15, ";\n        img {\n            min-width: 221px;\n            width: 221px;\n            height: 221px;\n            min-height: 221px;\n            max-height: 100%;\n            object-fit: contain;\n        }\n    "), props => props.first && !props.oneMatch ? "\n        p {\n            grid-column-start: 2;\n            grid-column-end: 4;\n            justify-self: end;\n            padding-right: 30px;\n        }\n        img {\n            grid-column-start: 4;\n            grid-column-end: 7;\n        }\n    " : !props.oneMatch ? "\n        p {\n            grid-column-start: 4;\n            grid-column-end: 6;\n            justify-self: start;\n            padding-left: 30px;\n        }\n        img {\n            grid-column-start: 1;\n            grid-column-end: 4;\n        }\n    " : '', props => props.isLoser ? '0.3' : '1', props => props.StyleTeamDetailContainer ? props.StyleTeamDetailContainer : '', props => props.StyleTeamDetailContainerLeft ? props.StyleTeamDetailContainerLeft : '', props => props.StyleTeamDetailContainerRight ? props.StyleTeamDetailContainerRight : '');

exports.StyleTeamDetailContainer = StyleTeamDetailContainer;

const StyleVersusDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleVersusDetailContainer",
  componentId: "sc-6n2kuj-21"
})(["margin:auto 0;text-align:center;align-items:center;grid-column-start:7;grid-column-end:9;", ""], props => props.cssVS || '');

exports.StyleVersusDetailContainer = StyleVersusDetailContainer;

const StyleScoreDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreDetailContainer",
  componentId: "sc-6n2kuj-22"
})(["display:flex;flex-direction:column;color:#FFF;margin:auto 0;grid-column-start:7;grid-column-end:9;", ";"], props => props.StyleScoreDetailContainer || '');

exports.StyleScoreDetailContainer = StyleScoreDetailContainer;

const StyleScoreDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreDetail",
  componentId: "sc-6n2kuj-23"
})(["display:grid;grid-template-columns:repeat(3,1fr);column-gap:20px;font-family:dharma-gothic-e-bold,sans-serif;font-weight:700;justify-items:center;color:#F5F5F5;", " ", " ", ""], props => props.oneMatch ? "\n        font-size: 180px;\n        line-height: 150px;\n        padding: 34px 0 25px 0;\n    " : "\n        font-size: 120px;\n        line-height: 100px;\n        padding: 20px 0 6px 0;\n    ", props => props.StyleScoreDetail || '', props => props.cssVS || '');

exports.StyleScoreDetail = StyleScoreDetail;

const StyleScoreSpan = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleScoreSpan",
  componentId: "sc-6n2kuj-24"
})(["min-width:41px;text-align:center;", ";", ""], props => props.StyleScoreSpan || '', props => props.winner && props.StyleScoreSpanWinner ? props.StyleScoreSpanWinner : '');

exports.StyleScoreSpan = StyleScoreSpan;

const StyleScoreDash = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleScoreDash",
  componentId: "sc-6n2kuj-25"
})(["position:relative;bottom:9px;"]); // export const StyleGamesDetail = styled.div`
//     display: grid;
//     font-size: 32px;
//     font-family: proxima-nova, sans-serif;
//     font-weight: 700;
// `;


exports.StyleScoreDash = StyleScoreDash;

const StyleGameDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleGameDetail",
  componentId: "sc-6n2kuj-26"
})(["display:grid;grid-template-columns:repeat(3,1fr);font-size:32px;line-height:32px;font-family:proxima-nova,sans-serif;font-weight:700;margin:5px auto;justify-items:center;", ";"], props => props.StyleGameDetail || '');

exports.StyleGameDetail = StyleGameDetail;