"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLogo = exports.StyledBar = exports.StyleTitleWrapper = exports.StyleTitleLogo = exports.StyleTitleContainer = exports.StyleTitle = exports.StyleSubTitle = exports.StyleMain = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-hh0qll-0"
})(["width:1920px;height:1080px;position:absolute;top:0;letter-spacing:3px;font-weight:unset;z-index:100;"]);

exports.StyleMain = StyleMain;

const StyleTitleContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTitleContainer",
  componentId: "sc-hh0qll-1"
})(["position:absolute;top:", ";left:211px;transition:transform 500ms ease-in-out,width 500ms ease-in-out,top 1s ease-in-out,clip-path 200ms ease-in;width:", ";transform:", ";url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/ScreenTitleV2_BG_MSI2021_left.png') left center,url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/ScreenTitleV2_BG_MSI2021_right.png') right 21px,url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/ScreenTitleV2_BG_MSI2021.png') 21px 21px;background-repeat:no-repeat;height:123px;color:#FFFFFF;display:grid;grid-template-columns:1fr auto 1fr;justify-content:center;align-items:center;padding-top:20px;text-transform:uppercase;", ";", ";"], props => props.show ? '150px' : '-200px', props => props.isResized ? '1180px' : '1479px', props => "\n        translateX(".concat(props.isResized ? '428.5px' : '0', ") \n        "), props => props.StyleTitleContainer || '', props => props.isResized ? props.StyleTitleContainerResizeOverWrite || '' : '');

exports.StyleTitleContainer = StyleTitleContainer;

const StyleTitleLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTitleLogo",
  componentId: "sc-hh0qll-2"
})(["width:90px;height:auto;"]);

exports.StyleTitleLogo = StyleTitleLogo;

const StyleTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTitleWrapper",
  componentId: "sc-hh0qll-3"
})(["display:flex;align-items:center;", ";"], props => props.StyleTitleWrapper || '');

exports.StyleTitleWrapper = StyleTitleWrapper;

const StyledLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLogo",
  componentId: "sc-hh0qll-4"
})(["position:absolute;z-index:2;bottom:0;left:0px;background:background-repeat:no-repeat;height:100%;width:100%;", ";", ";"], props => props.StyledLogo || '', props => props.isResized ? props.StyledLogoResized : '');

exports.StyledLogo = StyledLogo;

const StyledBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBar",
  componentId: "sc-hh0qll-5"
})(["background:blue;height:85%;width:100%;position:absolute;z-index:-1;bottom:0;left:22px;max-width:1460px;", ";background-size:contain;", ";"], props => props.StyledBar || '', props => props.finishedResize && props.StyledBarResizeOverWrite ? props.StyledBarResizeOverWrite : '');

exports.StyledBar = StyledBar;

const StyleTitle = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTitle",
  componentId: "sc-hh0qll-6"
})(["font-size:52px;height:75px;line-height:75px;min-width:200px;text-align:center;font-family:'titling-gothic-comp-bold';padding:3px 0;", ";"], props => props.StyledTitle || '');

exports.StyleTitle = StyleTitle;

const StyleSubTitle = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleSubTitle",
  componentId: "sc-hh0qll-7"
})(["font-size:28px;font-family:\"aeonik-regular\";text-align:", ";", ";", ";", ";"], props => props.align || 'inherit', props => props.StyleSubTitle || '', props => props.StyleSubTitleLeft || '', props => props.StyleSubTitleRight || '');

exports.StyleSubTitle = StyleSubTitle;