"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideo = exports.Base = exports.Background = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-rjk276-0"
})(["width:1920px;height:1080px;display:flex;flex-direction:row;position:relative;left:0;"]);

exports.Base = Base;

const StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-rjk276-1"
})(["height:100%;width:100%;background-color:transparent;position:absolute;z-index:-1;"]);

exports.StyleVideo = StyleVideo;

const BackgroundAnimation = _reactPose.default.div({
  'moveRight': {
    x: '0px',
    transition: {
      duration: 120000
    }
  },
  'moveLeft': {
    x: '-1880px',
    transition: {
      duration: 120000
    }
  }
});

const Background = (0, _styledComponents.default)(BackgroundAnimation).withConfig({
  displayName: "styles__Background",
  componentId: "sc-rjk276-2"
})(["background-image:", ";height:100%;width:200%;background-blend-mode:multiply;background-color:transparent;position:absolute;"], props => "url(".concat(props.pattern, ")"));
exports.Background = Background;