"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CornerLabelText = exports.CornerImage = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const CornerLogoAnimation = _reactPose.default.div({
  CornerLogoVisible: {
    top: props => props.endY,
    left: props => props.endX,
    transition: {
      duration: 700
    }
  },
  CornerLogoHidden: {
    top: props => props.startY,
    left: props => props.startX,
    transition: {
      duration: 700
    }
  }
});

const Container = (0, _styledComponents.default)(CornerLogoAnimation).withConfig({
  displayName: "styles__Container",
  componentId: "sc-tbp3s2-0"
})(["position:absolute;left:0;top:0;height:160px;width:400px;", ""], props => props.css);
exports.Container = Container;

const CornerLabelText = _styledComponents.default.p.withConfig({
  displayName: "styles__CornerLabelText",
  componentId: "sc-tbp3s2-1"
})(["font-family:'Refrigerator',sans-serif;font-weight:800;position:absolute;letter-spacing:1px;width:200px;text-align:center;z-index:10;font-size:30px;", ""], props => props.css);

exports.CornerLabelText = CornerLabelText;

const CornerImage = _styledComponents.default.img.withConfig({
  displayName: "styles__CornerImage",
  componentId: "sc-tbp3s2-2"
})(["position:absolute;top:", ";left:", ";"], props => props.imageTop, props => props.imageLeft);

exports.CornerImage = CornerImage;